import { useState } from "react";
import { postSignup } from "../../services"

export const useSignup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const signup = async (signupData) => {
    setIsLoading(true);
    const response = await postSignup(signupData);
    const json = await response.json();

    if (response.ok) {
      setIsLoading(false);
      setError(null);
      return json;
    }

    if (!response.ok) {
      setIsLoading(false);
      setError(json.message);
      return;
    }
  }

  return {signup, isLoading, error};
}
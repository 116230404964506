import React, { useState, useEffect, useRef } from "react";
import LayoutWithNavbar from "../../components/Layouts/LayoutWithNavbar/LayoutWithNavbar";
import { useAuthContext } from "../../hooks/useAuthContext";
import { GET_ROLES_STRING } from "../../common/constants";
import { useUpdateMyProfile } from "../../hooks/user/useUpdateMyProfile";
import { formatPhoneNumber } from "../../common/functions";
import ZipCodeInput from "../../components/ZipCodeInput/ZipCodeInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./Profile.scss";

const Profile = () => {
  const { user } = useAuthContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("1");
  // const [editHeight, setEditHeight] = useState(0);
  const [showEdit, setShowEdit] = useState(false);

  const editRef = useRef(null);

  const { updateMyProfile, isLoading, error, success, clearErrorAndSuccess } =
    useUpdateMyProfile();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await updateMyProfile({
      firstName,
      lastName,
      email: email.toLowerCase(),
      address,
      city,
      state,
      zip,
      phone: "+" + phone,
    });
  };

  const handleResetFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setAddress("");
    setCity("");
    setState("");
    setZip("");
    setPhone("1");
    clearErrorAndSuccess();
  };

  useEffect(() => {
    if (success) {
      handleResetFields();
    }
  }, [success]);

  const handleOpenEdit = () => {
    setShowEdit(!showEdit);
  };

  useEffect(() => {
    if (editRef.current && showEdit) {
      editRef.current.style.height = editRef.current.scrollHeight + "px";
    }

    if (!showEdit) {
      editRef.current.style.height = 0;
    }
  }, [error, showEdit]);

  return (
    <LayoutWithNavbar>
      <div className="section-main">
        <div className="w-layout-blockcontainer container small w-container">
          <div className="list">
            <div className="heading-div no-margin">
              <h6 className="subheading">Welcome {user.firstName},</h6>
              <div className="title-w-icon">
                <h2 className="heading-style-2 mb-0">My Account</h2>
                <button
                  data-w-id="6fd3b52e-e182-e963-0bf8-c9ccf0e916f0"
                  class="edit-icon w-inline-block p-0"
                  onClick={handleOpenEdit}
                >
                  <img
                    src={
                      require("../../common/assets/images/icon_edit.svg")
                        .default
                    }
                    loading="lazy"
                    alt=""
                    class="icon-title"
                  />
                </button>
              </div>
            </div>
            <div className="content-box-large user-margin">
              <div className="show">
                <div>
                  <div className="div-show _3-col">
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604aa-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>First Name</strong>
                      </p>
                      <p className="paragraph-2 _16">{user.firstName}</p>
                    </div>
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604b0-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>Last Name</strong>
                      </p>
                      <p className="paragraph-2 _16">{user.lastName}</p>
                    </div>
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604b6-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>Type</strong>
                      </p>
                      <p className="paragraph-2 _16">
                        <strong
                          className={
                            user.role === "SUPERADMIN"
                              ? "gold"
                              : user.role === "ADMIN" ||
                                user.role === "TECHNICIAN"
                              ? "cyan"
                              : user.role === "RETAIL"
                              ? "walmart"
                              : "customer"
                          }
                        >
                          {GET_ROLES_STRING[user.role]}
                        </strong>
                      </p>
                    </div>
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604bd-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>Address</strong>
                      </p>
                      <p className="paragraph-2 _16">
                        {user.address}
                        <br />
                      </p>
                    </div>
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604c4-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>City</strong>
                      </p>
                      <p className="paragraph-2 _16">{user.city}</p>
                    </div>
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604ca-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>State</strong>
                      </p>
                      <p className="paragraph-2 _16">{user.state}</p>
                    </div>
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604d0-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>ZIP Code</strong>
                      </p>
                      <p className="paragraph-2 _16">{user.zip}</p>
                    </div>
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604d6-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>Email</strong>
                      </p>
                      <p className="paragraph-2 _16">{user.email}</p>
                    </div>
                    <div id="w-node-_7c0cd5f5-5e84-62cd-a4de-b28e9a6604dc-a0211cb3">
                      <p className="paragraph-2 _16">
                        <strong>Phone Number</strong>
                      </p>
                      <div className="status-box">
                        <p className="paragraph-2 _16">
                          {formatPhoneNumber(user.phone)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div ref={editRef} className="show">
                <div
                  id="w-node-df5207f3-4e3b-0512-fb77-10dc25e4f9dd-a0211cb3"
                  className="divider"
                ></div>
                {!!success && (
                  <div className="hide-block mb-10">
                    <div className="invalid-box success-message">
                      <p className="paragraph-2 _16">
                        <strong>{success}</strong>
                      </p>
                    </div>
                  </div>
                )}
                {!!error && (
                  <div className="hide-block mb-10">
                    <div className="invalid-box">
                      {typeof error === "string" && (
                        <p className="paragraph-2 _16">
                          <strong>{error}</strong>
                        </p>
                      )}
                      {typeof error === "object" && (
                        <ul className="mb-0">
                          {error.map((errorItem, index) => (
                            <li key={index} className="paragraph-2 _16">
                              <strong>{errorItem}</strong>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                )}
                <div
                  id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df3218-a0211cb3"
                  className="div-view"
                >
                  <div
                    id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df3219-a0211cb3"
                    className="w-form"
                  >
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-3"
                      data-wf-page-id="666a0067587072a7a0211cb3"
                      data-wf-element-id="466b93fe-0d2f-e050-8c53-1000d9df321a"
                      onSubmit={handleSubmit}
                    >
                      <div id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df321b-a0211cb3">
                        <p className="paragraph-2 _16">
                          <strong>First Name</strong>
                        </p>
                        <input
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="First-Name"
                          data-name="First Name"
                          placeholder={user.firstName}
                          type="text"
                          id="First-Name"
                          value={firstName}
                          onChange={(e) => {
                            clearErrorAndSuccess();
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df3220-a0211cb3">
                        <p className="paragraph-2 _16">
                          <strong>Last Name</strong>
                        </p>
                        <input
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="Last-Name"
                          data-name="Last Name"
                          placeholder={user.lastName}
                          type="text"
                          id="Last-Name"
                          value={lastName}
                          onChange={(e) => {
                            clearErrorAndSuccess();
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                      <div id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df3225-a0211cb3">
                        <p className="paragraph-2 _16">
                          <strong>Email</strong>
                        </p>
                        <input
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="Email-Address"
                          data-name="Email Address"
                          placeholder={user.email}
                          type="text"
                          id="Email-Address"
                          value={email}
                          onChange={(e) => {
                            clearErrorAndSuccess();
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df322a-a0211cb3">
                        <p className="paragraph-2 _16">
                          <strong>Street Address</strong>
                        </p>
                        <input
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="Street-Address"
                          data-name="Street Address"
                          placeholder={user.address}
                          type="text"
                          id="Street-Address"
                          value={address}
                          onChange={(e) => {
                            clearErrorAndSuccess();
                            setAddress(e.target.value);
                          }}
                        />
                        <p className="paragraph-2 _16">
                          <strong>City</strong>
                        </p>
                        <input
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="field-8"
                          data-name="Field 8"
                          placeholder={user.city}
                          type="text"
                          id="field-8"
                          value={city}
                          onChange={(e) => {
                            clearErrorAndSuccess();
                            setCity(e.target.value);
                          }}
                        />
                      </div>
                      <div id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df323c-a0211cb3">
                        <div className="_2-col-grid">
                          <div id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df3234-a0211cb3">
                            <label
                              htmlFor="Location-4"
                              className="field-label-3"
                            >
                              State
                            </label>
                            <input
                              className="text-field-2 w-input"
                              maxLength="256"
                              name="Location-3"
                              data-name="Location 3"
                              placeholder={user.state}
                              type="text"
                              id="Location-3"
                              required=""
                              value={state}
                              onChange={(e) => {
                                clearErrorAndSuccess();
                                setState(e.target.value);
                              }}
                            />
                          </div>
                          <div id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df3238-a0211cb3">
                            <label
                              htmlFor="Location-3"
                              className="field-label-3"
                            >
                              ZIP Code
                            </label>
                            <ZipCodeInput
                              classes={"text-field-2 w-input"}
                              placeholder={user.zip}
                              zip={zip}
                              setZip={setZip}
                            />
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_466b93fe-0d2f-e050-8c53-1000d9df3241-a0211cb3">
                        <p className="paragraph-2 _16">
                          <strong>Phone Number</strong>
                        </p>
                        <PhoneInput
                          containerClass="text-field-2"
                          inputClass="text-field-2 w-input w-100 h-100"
                          dropdownClass="d-none"
                          buttonClass="d-none"
                          disableSearchIcon={false}
                          countryCodeEditable={false}
                          country={"us"}
                          onlyCountries={["us", "ca"]}
                          value={phone}
                          onChange={(value) => setPhone(value)}
                        />
                        <div className="button-div right-align">
                          <button
                            type="button"
                            data-w-id="9518481e-e590-5721-9fe7-9f71491b4f33"
                            className="button-cancel w-button"
                            onClick={() => {
                              handleResetFields();
                              setShowEdit(false);
                              // setEditHeight(0);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="button-main w-button"
                            disabled={isLoading}
                          >
                            Apply Changes
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithNavbar>
  );
};

export default Profile;

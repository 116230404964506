import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVerify } from "../../hooks/auth/useVerify";

const Verify = () => {
  const navigate = useNavigate();
  const { userId, verifyCode } = useParams();
  const { verifyEmail, verifyError } = useVerify();

  useEffect(() => {
    verify();
  }, []);

  const verify = async () => {
    const result = await verifyEmail(userId, verifyCode);

    if (result) {
      localStorage.setItem("verifiedLogin", true);
      navigate("/login");
    }
  };

  return (
    <div className="w-100vw h-100vh d-flex align-items-center justify-content-center">
      {!verifyError && <img
        src={require("../../common/assets/images/loading-buffering.gif")}
        width="100"
        height="100"
        loading="lazy"
        alt=""
        className="margin-auto mt-20 mb-20"
      />}
      {!!verifyError && (
        <div className="hide-block">
          <div className="invalid-box ps-10 pe-10">
            {typeof verifyError === "string" && (
              <p className="paragraph-2 _16">
                <strong>{verifyError}</strong>
              </p>
            )}
            {typeof verifyError === "object" && (
              <ul className="mb-0">
                {verifyError.map((errorItem, index) => (
                  <li key={index} className="paragraph-2 _16">
                    <strong>{errorItem}</strong>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Verify;

import React, { useState } from "react";
import { useLogout } from "../../hooks/auth/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";

import "./Navbar.scss";

const Navbar = () => {
  const { logout } = useLogout();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { user } = useAuthContext();

  const isAdmin = user?.role === "SUPERADMIN" || user?.role === "ADMIN";

  const handleHamburgerClick = () => {
    if (!showMobileMenu) {
      setShowMobileMenu(!showMobileMenu);
      setTimeout(() => {
        setShowMobileNav(!showMobileNav);
      }, 100);
    } else {
      setShowMobileNav(!showMobileNav);
      setTimeout(() => {
        setShowMobileMenu(!showMobileMenu);
      }, 100);
    }
  };

  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="nav w-nav"
    >
      <div className="w-layout-blockcontainer container-nav w-container">
        <a href="/" className="brand w-nav-brand">
          <img
            src={
              require("../../common/assets/images/horizontal-on-white.svg")
                .default
            }
            loading="lazy"
            alt=""
            className="logo"
          />
        </a>
        <nav role="navigation" className="nav-menu-4 w-nav-menu">
          {/* <a href="/repair" className="nav-link w-nav-link">
            Repair Form
          </a> */}
          <a href="/profile" className="nav-link w-nav-link">
            My Account
          </a>
          <a href="/bookings" className="nav-link w-nav-link">
            My Bookings
          </a>
          {isAdmin && (
            <a href="/admin" className="nav-link w-nav-link">
              Admin
            </a>
          )}
          <button
            type="button"
            className="btn-logout nav-link w-nav-link"
            onClick={logout}
          >
            Log Out
          </button>
        </nav>
        <div
          className={`menu-button w-nav-button ${
            showMobileMenu ? "w--open" : ""
          }`}
          onClick={handleHamburgerClick}
        >
          <div className="icon w-icon-nav-menu"></div>
        </div>
      </div>
      <div
        className="w-nav-overlay"
        data-wf-ignore=""
        id="w-nav-overlay-0"
        style={{
          display: showMobileMenu ? "block" : "none",
        }}
        onClick={() => {
          setShowMobileNav(false);
          setTimeout(() => {
            setShowMobileMenu(false);
          }, 100);
        }}
      >
        <nav
          role="navigation"
          className="nav-menu-4 w-nav-menu"
          data-nav-menu-open=""
          style={{
            transform: `translateY(${
              showMobileNav ? "0px" : "-100%"
            }) translateX(0px)`,
          }}
        >
          {/* <a href="/repair" className="nav-link w-nav-link w--nav-link-open">
            Repair Form
          </a> */}
          <a href="/profile" className="nav-link w-nav-link w--nav-link-open">
            My Account
          </a>
          <a href="/bookings" className="nav-link w-nav-link w--nav-link-open">
            My Bookings
          </a>
          {isAdmin && (
            <a
              href="/admin"
              aria-current="page"
              className="nav-link w-nav-link w--current w--nav-link-open"
            >
              Admin
            </a>
          )}
          <button
            type="button"
            className="btn-logout nav-link w-nav-link w--nav-link-open"
            onClick={logout}
          >
            Log Out
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;

import React from "react";
import LayoutWithNavbar from "../../components/Layouts/LayoutWithNavbar/LayoutWithNavbar";

const ConfirmService = () => {
  return (
    <LayoutWithNavbar>
      <section className="page-wrap padding">
        <div className="content-box center">
          <img
            src={require("../../common/assets/images/email.svg").default}
            loading="lazy"
            alt=""
            width="56"
            className="icon-main"
          />
          <h1 className="heading-smaller">Booking Submitted!</h1>
          <p className="paragraph-2 _16">
            You have been sent an email with a password to check the status of
            your service booking.
            <br />
            <br />
            If you don&#x27;t see it in your inbox, make sure to check your spam
            folder.
          </p>
        </div>
      </section>
    </LayoutWithNavbar>
  );
};

export default ConfirmService;

export const SIGNUP_URL = `${process.env.REACT_APP_SERVER_URL}auth/signup`;
export const VERIFY_URL = `${process.env.REACT_APP_SERVER_URL}auth/verify`;
export const FORGOT_PASSWORD_URL = `${process.env.REACT_APP_SERVER_URL}auth/forgotPassword`;
export const RESEND_VERYFY_EMAIL_URL = `${process.env.REACT_APP_SERVER_URL}emails/resendEmailVerify`;
export const LOGIN_URL = `${process.env.REACT_APP_SERVER_URL}auth/signin`;
export const GET_ME_URL = `${process.env.REACT_APP_SERVER_URL}users/me`;
export const USERS_URL = `${process.env.REACT_APP_SERVER_URL}users`;
export const STORES_URL = `${process.env.REACT_APP_SERVER_URL}stores`;
export const GET_NEAR_STORES_URL = `${process.env.REACT_APP_SERVER_URL}stores/near`;
export const GET_NEAR_STORES_WITHOUT_TOKEN_URL = `${process.env.REACT_APP_SERVER_URL}stores/nearWithoutToken`;
export const SERVICES_URL = `${process.env.REACT_APP_SERVER_URL}services`;
export const SERVICES_WITHOUT_TOKEN_URL = `${process.env.REACT_APP_SERVER_URL}services/withoutToken`;
export const GET_SERVICES_FROM_IDS_URL = `${process.env.REACT_APP_SERVER_URL}services/fromIds`;
export const BOOKINGS_URL = `${process.env.REACT_APP_SERVER_URL}bookings`;
export const BOOKING_COMMENTS_URL = `${process.env.REACT_APP_SERVER_URL}bookingComments`;
export const GET_ORDERS_FROM_LOCALLY_URL = `${process.env.REACT_APP_LOCALLY_SERVER_URL}stores/search`;
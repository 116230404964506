import { useState } from "react";
import { useAuthContext } from "../useAuthContext";
import { getStoreBookings } from "../../services";

export const useStoreBookings = () => {
  const {accessToken} = useAuthContext();
  const [isGetting, setIsGetting] = useState(false);
  const [gettingError, setGettingError] = useState(null);

  const getBookings = async (storeId, qString = "", qDate = "") => {
    setIsGetting(true);
    const response = await getStoreBookings(storeId, accessToken, qString, qDate);
    const json = await response.json();

    if (response.ok) {
      setIsGetting(false);
      setGettingError(null);
      return json;
    }

    if (!response.ok) {
      setIsGetting(false);
      setGettingError(json.message);
      return;
    }
  }

  return {getBookings, isGetting, gettingError};
}
import { useState } from "react"
import { resendVerifyEmail } from "../../services";

export const useResendEmail = () => {
  const [isLoading, setIsLoading] = useState(false);

  const resendEmail = async (email) => {
    setIsLoading(true);
    const response = await resendVerifyEmail(email);
    const json = await response.json();

    if (response.ok) {
      setIsLoading(false);
      return json;
    }

    if (!response.ok) {
      setIsLoading(false);
      return;
    }
  }

  return {resendEmail, isLoading};
}
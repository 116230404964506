import { useState } from "react";
import {
  getAllServices,
  getNearStores,
  getAllServicesWithoutToken,
  getNearStoresWithoutToken,
  getAllLocallyOrdersOfStore,
} from "../../services";
import { useAuthContext } from "../useAuthContext";
import {
  createABooking,
  createABookingWithoutToken,
} from "../../services/booking";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

export const useCreateBooking = () => {
  const navigate = useNavigate();
  const { user, accessToken } = useAuthContext();
  const [isGettingStores, setIsGettingStores] = useState(false);
  const [isGettingServices, setIsGettingServices] = useState(false);
  const [isGettingScheduledDates, setIsGettingScheduledDates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getStores = async (location) => {
    setIsGettingStores(true);
    const response = await getNearStores(location, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsGettingStores(false);
      return json;
    }

    if (!response.ok) {
      setIsGettingStores(false);
      return [];
    }
  };

  const getServices = async () => {
    setIsGettingServices(true);
    const response = await getAllServices(accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsGettingServices(false);
      return json;
    }

    if (!response.ok) {
      setIsGettingServices(false);
      return [];
    }
  };

  const getStoresWithoutToken = async (location) => {
    setIsGettingStores(true);
    const response = await getNearStoresWithoutToken(location);
    const json = await response.json();

    if (response.ok) {
      setIsGettingStores(false);
      return json;
    }

    if (!response.ok) {
      setIsGettingStores(false);
      return [];
    }
  };

  const getServicesWithoutToken = async () => {
    setIsGettingServices(true);
    const response = await getAllServicesWithoutToken();
    const json = await response.json();

    if (response.ok) {
      setIsGettingServices(false);
      return json;
    }

    if (!response.ok) {
      setIsGettingServices(false);
      return [];
    }
  };

  const createBooking = async (data) => {
    setIsLoading(true);
    setError(null);
    let response;
    if (!!user) {
      response = await createABooking(data, accessToken);
    } else {
      response = await createABookingWithoutToken(data);
    }
    const json = await response.json();

    if (response.ok) {
      setIsLoading(false);
      if (!!user) {
        navigate("/confirm-service");
      } else {
        navigate(`/confirm-email/${data.contactEmail}`);
      }
    }

    if (!response.ok) {
      if (response.status === 404) {
        setError("Cannot connect to server");
      } else {
        setError(json.message);
      }
      setIsLoading(false);
    }
  };

  const getScheduledDates = async (storeNumber) => {
    setIsGettingScheduledDates(true);
    const response = await getAllLocallyOrdersOfStore(storeNumber);
    const json = await response.json();
    let localTimeZone = "America/Edmonton";

    if (response.ok) {
      if (!json.data.data) {
        setIsGettingScheduledDates(false);
        return [];
      }

      const scheduledDates = json.data.data.map((order) =>
        moment(
          moment
            .utc(order.orderTime.scheduledStartDate)
            .tz(order.orderTime.localTimeZone)
        ).format("YYYY-MM-DD h:mm A")
      );

      if (json?.data?.data?.length > 0) {
        localTimeZone = json.data.data[0].orderTime.localTimeZone;
      }

      let resultDates = [];
      for (let i = 0; i < scheduledDates.length; i++) {
        resultDates.push(
          moment(scheduledDates[i], "YYYY-MM-DD h:mm A")
            .add(0, "hours")
            .format("YYYY-MM-DD h:mm A")
        );
        resultDates.push(
          moment(scheduledDates[i], "YYYY-MM-DD h:mm A")
            .add(1, "hours")
            .format("YYYY-MM-DD h:mm A")
        );
        resultDates.push(
          moment(scheduledDates[i], "YYYY-MM-DD h:mm A")
            .add(2, "hours")
            .format("YYYY-MM-DD h:mm A")
        );
        resultDates.push(
          moment(scheduledDates[i], "YYYY-MM-DD h:mm A")
            .add(3, "hours")
            .format("YYYY-MM-DD h:mm A")
        );
      }
      setIsGettingScheduledDates(false);
      return { dates: resultDates, localTimeZone: localTimeZone };
    }

    if (!response.ok) {
      setIsGettingScheduledDates(false);
      return [];
    }
  };

  return {
    getStores,
    isGettingStores,
    getServices,
    isGettingServices,
    createBooking,
    isLoading,
    error,
    setError,
    getServicesWithoutToken,
    getStoresWithoutToken,
    getScheduledDates,
    isGettingScheduledDates,
  };
};

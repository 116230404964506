import React from "react";
import { GET_ROLES_STRING } from "../../common/constants";

const AdminUser = ({ user }) => {
  return (
    <a
      href={`/view-user/${user.id}`}
      className="content-box-list w-inline-block"
    >
      <div className="box-click three-col">
        <div>
          <p className="paragraph-2 _16">
            {user.firstName} {user.lastName}
          </p>
        </div>
        <div id="w-node-df625f38-95ed-9a8f-aeee-37020d67e0c6-740a7782">
          <p className="paragraph-2 _16">{user.email}</p>
        </div>
        <div>
          <div className="status-box">
            <p className="paragraph-2 _16 no-margin">
              <strong
                className={
                  user.role === "SUPERADMIN"
                    ? "gold"
                    : user.role === "ADMIN" || user.role === "TECHNICIAN"
                    ? "cyan"
                    : user.role === "RETAIL"
                    ? "walmart"
                    : "customer"
                }
              >
                {GET_ROLES_STRING[user.role]}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default AdminUser;

import { SERVICES_URL, SERVICES_WITHOUT_TOKEN_URL } from "../common/constants";
import { GET_SERVICES_FROM_IDS_URL } from "../common/constants";

export const getAllServices = async (accessToken, qString = "") => {
  return await fetch(SERVICES_URL + "?q=" + qString, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getAllServicesWithoutToken = async (qString = "") => {
  return await fetch(SERVICES_WITHOUT_TOKEN_URL + "?q=" + qString, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getServicesFromIds = async (data, accessToken) => {
  return await fetch(GET_SERVICES_FROM_IDS_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const createAService = async (data, accessToken) => {
  return await fetch(SERVICES_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const updateService = async (serviceId, data, accessToken) => {
  return await fetch(SERVICES_URL + "/" + serviceId, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const deleteService = async (serviceId, accessToken) => {
  return await fetch(SERVICES_URL + "/" + serviceId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

import React, { useState } from "react";
import LayoutWithNavbar from "../../components/Layouts/LayoutWithNavbar/LayoutWithNavbar";
import AdminBookings from "./tabs/AdminBookings";
import AdminUsers from "./tabs/AdminUsers";
import AdminStores from "./tabs/AdminStores";
import AdminServices from "./tabs/AdminServices";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";

import "./Admin.scss";

const Admin = () => {
	const navigate = useNavigate();
  const { tab } = useParams();
  const initIndex =
    tab === "bookings"
      ? 0
      : tab === "users"
      ? 1
      : tab === "stores"
      ? 2
      : tab === "services"
      ? 3
      : 2;
  const { user } = useAuthContext();
  const [activeTabIndex, setActiveTabIndex] = useState(initIndex);

  return (
    <LayoutWithNavbar>
      <div className="section-main">
        <div className="w-layout-blockcontainer container small w-container">
          <div>
            <div className="heading-div no-margin">
              <h6 className="subheading">Welcome {user.firstName},</h6>
              <h2 className="heading-style-2">Admin Portal</h2>
            </div>
            <div
              data-current="Stores"
              data-easing="ease"
              data-duration-in="300"
              data-duration-out="100"
              className="tabs w-tabs"
            >
              <div className="tabs-menu w-tab-menu">
                <a
                  data-w-tab="Bookings"
                  className={`tab-link w-inline-block w-tab-link ${
                    activeTabIndex === 0 ? "w--current" : ""
                  }`}
                  onClick={() => {
										navigate("/admin/bookings");
										setActiveTabIndex(0);
									}}
                >
                  <div>Bookings</div>
                </a>
                <a
                  data-w-tab="Users"
                  className={`tab-link w-inline-block w-tab-link ${
                    activeTabIndex === 1 ? "w--current" : ""
                  }`}
                  onClick={() => {
										navigate("/admin/users");
										setActiveTabIndex(1);
									}}
                >
                  <div>Users</div>
                </a>
                <a
                  data-w-tab="Stores"
                  className={`tab-link w-inline-block w-tab-link ${
                    activeTabIndex === 2 ? "w--current" : ""
                  }`}
                  onClick={() => {
										navigate("/admin/stores");
										setActiveTabIndex(2);
									}}
                >
                  <div>Stores</div>
                </a>
                <a
                  data-w-tab="Services"
                  className={`tab-link w-inline-block w-tab-link ${
                    activeTabIndex === 3 ? "w--current" : ""
                  }`}
                  onClick={() => {
										navigate("/admin/services");
										setActiveTabIndex(3);
									}}
                >
                  <div>Services</div>
                </a>
              </div>
              <div className="tabs-content w-tab-content">
                <AdminBookings activeTabIndex={activeTabIndex} />
                <AdminUsers activeTabIndex={activeTabIndex} />
                <AdminStores activeTabIndex={activeTabIndex} />
                <AdminServices activeTabIndex={activeTabIndex} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithNavbar>
  );
};

export default Admin;

import "./ServiceRadioItem.scss";

const ServiceRadioItem = ({
  service,
  selectedServices,
  handleServiceClick,
}) => {
  return (
    <div
      className={`w-checkbox checkbox-field-2 checkbox-base ${
        selectedServices.includes(service) ? "service-checked" : ""
      }`}
      onClick={() => handleServiceClick(service)}
    >
      <input
        id="New Business"
        name="New-Business"
        data-name="New Business"
        type="checkbox"
        data-w-id="12a65da8-3db3-f178-7259-8c01268a28cc"
        className="w-checkbox-input checkbox"
      />
      <div className="checkbox-text-wrapper pointer">
        <h4 className="checkbox-h4">{service.name}</h4>
        <div className="checkbox-text">{service.description}</div>
      </div>
      <span htmlFor="New-Business" className="checkbox-title w-form-label">
        Yes!
      </span>
    </div>
  );
};

export default ServiceRadioItem;

import { useEffect, useState } from "react";
import AdminBooking from "../../../components/AdminBooking/AdminBooking";
import { useMyBooking } from "../../../hooks/booking/useMyBooking";
import NoItems from "../../../components/NoItems/NoItems";
import moment from "moment";

const AdminBookings = ({ activeTabIndex }) => {
  const [bookings, setBookings] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const { getBookings, gettingError, isLoading, deleteBooking } =
    useMyBooking();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const bookings = await getBookings();
    setBookings(bookings);
  };

  const handleChangeSearchString = (e) => {
    setSearchString(e.target.value);

    if (e.target.value === "") {
      getData();
    }
  };

  const handleFilter = async (e) => {
    e.preventDefault();

    let bookings;
    if (!!Date.parse(searchString)) {
      bookings = await getBookings(
        searchString,
        moment(new Date(searchString)).toISOString()
      );
    } else {
      bookings = await getBookings(searchString);
    }
    setBookings(bookings);
  };

  const handleCancelBooking = (bookingId) => {
    setSelectedId(bookingId);
    setShowRemoveDialog(true);
  };

  const handleConfirmDelete = async () => {
    const deleteResult = await deleteBooking(selectedId);

    if (deleteResult) {
      setBookings(bookings.filter((booking) => booking.id !== selectedId));
    }

    setShowRemoveDialog(false);
  };

  return (
    <div
      data-w-tab="Bookings"
      className={`w-tab-pane ${activeTabIndex === 0 ? "w--tab-active" : ""}`}
    >
      <div className="list">
        <div>
          <div
            id="w-node-_8b7332c4-3bd2-e30e-a258-6c7e316a7623-740a7782"
            className="no-margin w-form"
          >
            <form
              id="wf-form-Search"
              name="wf-form-Search"
              data-name="Search"
              method="get"
              className="search-box"
              data-wf-page-id="6645134aefdb1722740a7782"
              data-wf-element-id="8b7332c4-3bd2-e30e-a258-6c7e316a7624"
              onSubmit={handleFilter}
            >
              <input
                className="text-field-2 no-margin w-input"
                maxLength="256"
                name="field-4"
                data-name="Field 4"
                placeholder="BN/Name/Date/Status"
                type="text"
                id="field-4"
                required=""
                value={searchString}
                onChange={handleChangeSearchString}
              />
              <button
                type="submit"
                data-wait="Please wait..."
                className="button-three w-button"
              >
                Filter
              </button>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        {isLoading && (
          <img
            src={require("../../../common/assets/images/loading-buffering.gif")}
            width="100"
            height="100"
            loading="lazy"
            alt=""
            className="margin-auto mt-20 mb-20"
          />
        )}
        {!!gettingError && (
          <div className="hide-block mb-10">
            <div className="invalid-box">
              <p className="paragraph-2 _16">
                <strong>{gettingError}</strong>
              </p>
            </div>
          </div>
        )}
        {bookings.map((booking) => (
          <AdminBooking
            key={booking.id}
            booking={booking}
            handleCancel={() => handleCancelBooking(booking.id)}
          />
        ))}
        {!isLoading && bookings.length === 0 && (
          <NoItems description="No bookings found." />
        )}
      </div>
      <div
        className="lightbox"
        style={{
          display: showRemoveDialog ? "flex" : "none",
        }}
      >
        <div className="content-box-large lightbox-content">
          <div>
            <p className="paragraph-2 _16">
              Are you sure you would like to delete this service?
            </p>
            <div className="button-div full-width">
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b4"
                className="button-main full-width w-button"
                onClick={() => setShowRemoveDialog(false)}
              >
                Back
              </button>
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b6"
                className="button-cancel delete full-width w-button"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBookings;

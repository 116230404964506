import { useAuthContext } from "../useAuthContext";
import {
  createBookingComment,
  getCommentsOfABooking,
  editBooking,
} from "../../services";
import { useState } from "react";

export const useMyOneBooking = () => {
  const { accessToken } = useAuthContext();
  const [isCreatingComment, setIsCreatingComment] = useState(false);
  const [isGetting, setIsGetting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const createComment = async (content, text, bookingId) => {
    setIsCreatingComment(true);

    const response = await createBookingComment(
      {
        content,
        text, 
        bookingId,
      },
      accessToken
    );
    const json = await response.json();

    if (response.ok) {
      setIsCreatingComment(false);
    }

    if (!response.ok) {
      setIsCreatingComment(false);
    }
  };

  const getComments = async (bookingId) => {
    setIsGetting(true);

    const response = await getCommentsOfABooking(bookingId, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsGetting(false);
      return json;
    }

    if (!response.ok) {
      setIsGetting(false);
      return [];
    }
  };

  const updateBooking = async (bookingId, data) => {
    setIsUpdating(true);
    const response = await editBooking(bookingId, data, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsUpdating(false);
      return json;
    }

    if (!response.ok) {
      setIsUpdating(false);
      return;
    }
  };

  return {
    createComment,
    isCreatingComment,
    getComments,
    isGetting,
    updateBooking,
    isUpdating,
  };
};

import React, { useEffect, useState } from "react";
import LayoutWithNavbar from "../../components/Layouts/LayoutWithNavbar/LayoutWithNavbar";
import AdminBooking from "../../components/AdminBooking/AdminBooking";
import { useNavigate, useParams } from "react-router-dom";
import { useAdminUsers } from "../../hooks/admin/useAdminUsers";
import { GET_ROLES_STRING } from "../../common/constants";
import { formatPhoneNumber } from "../../common/functions";
import { useViewUser } from "../../hooks/user/useViewUser";
import { useMyBooking } from "../../hooks/booking/useMyBooking";
import ZipCodeInput from "../../components/ZipCodeInput/ZipCodeInput";
import { useAuthContext } from "../../hooks/useAuthContext";
import NoItems from "../../components/NoItems/NoItems";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const roles = {
  super_admin: "Super Admin",
  admin: "Admin",
  technician: "Technician",
  retail: "Retail",
  customer: "Customer",
};

const ViewUser = () => {
  const { user: adminUser } = useAuthContext();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("1");
  const [role, setRole] = useState("");
  const [registrationCode, setRegistrationCode] = useState("");
  const [bookings, setBookings] = useState([]);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [showRemoveUserDialog, setShowRemoveUserDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const { getAUser, getAUserError } = useAdminUsers();
  const {
    updateUser,
    error,
    success,
    isLoading,
    getAllUserBookings,
    deleteUser,
    isDeleting,
    deleteError,
  } = useViewUser();
  const { deleteBooking } = useMyBooking();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const userResult = await getAUser(userId);
    setUser(userResult);
    setRole(userResult.role);
    const bookingsResult = await getAllUserBookings(userId);
    setBookings(bookingsResult);
  };

  const handleChange = async (e) => {
    const updatedUser = await updateUser(userId, {
      firstName,
      lastName,
      email: email.toLowerCase(),
      address,
      city,
      state,
      zip,
      phone: "+" + phone,
      role,
      registrationCode,
    });

    if (!!updatedUser) {
      setUser(updatedUser);
      setFirstName("");
      setLastName("");
      setEmail("");
      setAddress("");
      setCity("");
      setState("");
      setZip("");
      setPhone("1");
      setRole("");
      setRegistrationCode("");
    }
  };

  const handleResetFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setAddress("");
    setCity("");
    setState("");
    setZip("");
    setPhone("");
    setRole("");
    setRegistrationCode("");

    navigate("/admin/users");
  };

  const handleCancelBooking = (bookingId) => {
    setSelectedId(bookingId);
    setShowRemoveDialog(true);
  };

  const handleConfirmDelete = async () => {
    const deleteResult = await deleteBooking(selectedId);

    if (deleteResult) {
      setBookings(bookings.filter((booking) => booking.id !== selectedId));
    }

    setShowRemoveDialog(false);
  };

  const handleDeleteUser = () => {
    setShowRemoveUserDialog(true);
  };

  const handleConfirmDeleteUser = async () => {
    setShowRemoveUserDialog(false);
    const deletedUser = await deleteUser(userId);
    if (deletedUser) {
      navigate("/admin/users");
    }
  };

  return (
    <LayoutWithNavbar>
      <div className="section-main">
        <div className="w-layout-blockcontainer container small w-container">
          {!!getAUserError && (
            <div className="hide-block mb-10">
              <div className="invalid-box">
                <p className="paragraph-2 _16">
                  <strong>{getAUserError}</strong>
                </p>
              </div>
            </div>
          )}
          {!!user && (
            <div>
              <div className="heading-div no-margin">
                <h6 className="subheading">View User</h6>
                <h2 className="heading-style-2">
                  {user.firstName} {user.lastName}
                </h2>
              </div>
              <div className="list">
                <div className="content-box-large user-margin">
                  <div className="show">
                    <div>
                      <div className="div-show _3-col">
                        <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517ce4-6400d267">
                          <p className="paragraph-2 _16">
                            <strong>First Name</strong>
                          </p>
                          <p className="paragraph-2 _16">{user.firstName}</p>
                        </div>
                        <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517cea-6400d267">
                          <p className="paragraph-2 _16">
                            <strong>Last Name</strong>
                          </p>
                          <p className="paragraph-2 _16">{user.lastName}</p>
                        </div>
                        <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517cf0-6400d267">
                          <p className="paragraph-2 _16">
                            <strong>Type</strong>
                          </p>
                          <p className="paragraph-2 _16">
                            <strong
                              className={
                                user.role === "SUPERADMIN"
                                  ? "gold"
                                  : user.role === "ADMIN" ||
                                    user.role === "TECHNICIAN"
                                  ? "cyan"
                                  : user.role === "RETAIL"
                                  ? "walmart"
                                  : "customer"
                              }
                            >
                              {GET_ROLES_STRING[user.role]}
                            </strong>
                          </p>
                        </div>
                        {!(
                          (user.role === "SUPERADMIN" &&
                            adminUser.role === "ADMIN") ||
                          user.role === adminUser.role
                        ) && (
                          <>
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517cf7-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>Address</strong>
                              </p>
                              <p className="paragraph-2 _16">
                                {user.address}
                                <br />
                              </p>
                            </div>
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517cfe-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>City</strong>
                              </p>
                              <p className="paragraph-2 _16">{user.city}</p>
                            </div>
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d04-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>State</strong>
                              </p>
                              <p className="paragraph-2 _16">{user.state}</p>
                            </div>
                          </>
                        )}
                        <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d0a-6400d267">
                          <p className="paragraph-2 _16">
                            <strong>ZIP Code</strong>
                          </p>
                          <p className="paragraph-2 _16">{user.zip}</p>
                        </div>
                        <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d10-6400d267">
                          <p className="paragraph-2 _16">
                            <strong>Email</strong>
                          </p>
                          <p className="paragraph-2 _16">{user.email}</p>
                        </div>
                        <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d16-6400d267">
                          <p className="paragraph-2 _16">
                            <strong>Phone Number</strong>
                          </p>
                          <div className="status-box">
                            <p className="paragraph-2 _16">
                              {formatPhoneNumber(user.phone)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!(
                    (user.role === "SUPERADMIN" &&
                      adminUser.role === "ADMIN") ||
                    user.role === adminUser.role
                  ) && (
                    <>
                      <div
                        id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d1d-6400d267"
                        className="divider"
                      ></div>
                      {!!success && (
                        <div className="hide-block mb-10">
                          <div className="invalid-box success-message">
                            <p className="paragraph-2 _16">
                              <strong>{success}</strong>
                            </p>
                          </div>
                        </div>
                      )}
                      {!!error && (
                        <div className="hide-block mb-10">
                          <div className="invalid-box">
                            {typeof error === "string" && (
                              <p className="paragraph-2 _16">
                                <strong>{error}</strong>
                              </p>
                            )}
                            {typeof error === "object" && (
                              <ul className="mb-0">
                                {error.map((errorItem, index) => (
                                  <li key={index} className="paragraph-2 _16">
                                    <strong>{errorItem}</strong>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d1e-6400d267"
                        className="div-view"
                      >
                        <div
                          id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d1f-6400d267"
                          className="w-form"
                        >
                          <form
                            id="email-form"
                            name="email-form"
                            data-name="Email Form"
                            method="post"
                            className="form-3"
                            data-wf-page-id="66464dbd1e1defde6400d267"
                            data-wf-element-id="9507b2eb-0716-95ad-29ed-4bcca6517d20"
                          >
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d21-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>First Name</strong>
                              </p>
                              <input
                                className="text-field-2 w-input"
                                maxLength="256"
                                name="First-Name-2"
                                data-name="First Name 2"
                                placeholder={user.firstName}
                                type="text"
                                id="First-Name-2"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div>
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d26-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>Last Name</strong>
                              </p>
                              <input
                                className="text-field-2 w-input"
                                maxLength="256"
                                name="Last-Name-2"
                                data-name="Last Name 2"
                                placeholder={user.lastName}
                                type="text"
                                id="Last-Name-2"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d2b-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>Email</strong>
                              </p>
                              <input
                                className="text-field-2 w-input"
                                maxLength="256"
                                name="Email-Address-2"
                                data-name="Email Address 2"
                                placeholder={user.email}
                                type="text"
                                id="Email-Address-2"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d30-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>Street Address</strong>
                              </p>
                              <input
                                className="text-field-2 w-input"
                                maxLength="256"
                                name="Street-Address-2"
                                data-name="Street Address 2"
                                placeholder={user.address}
                                type="text"
                                id="Street-Address-2"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d39-6400d267">
                              <div className="_2-col-grid">
                                <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d3b-6400d267">
                                  <label
                                    htmlFor="Location-4"
                                    className="field-label-3"
                                  >
                                    State
                                  </label>
                                  <input
                                    className="text-field-2 w-input"
                                    maxLength="256"
                                    name="Location-3"
                                    data-name="Location 3"
                                    placeholder={user.state}
                                    type="text"
                                    id="Location-3"
                                    required=""
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                  />
                                </div>
                                <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d3f-6400d267">
                                  <label
                                    htmlFor="Location-4"
                                    className="field-label-3"
                                  >
                                    ZIP Code
                                  </label>
                                  <ZipCodeInput
                                    classes={"text-field-2 w-input"}
                                    placeholder={user.zip}
                                    zip={zip}
                                    setZip={setZip}
                                  />
                                </div>
                              </div>
                            </div>
                            <div id="w-node-_9507b2eb-0716-95ad-29ed-4bcca6517d43-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>Phone Number</strong>
                              </p>
                              <PhoneInput
                                containerClass="text-field-2"
                                inputClass="text-field-2 w-input w-100 h-100"
                                dropdownClass="d-none"
                                buttonClass="d-none"
                                disableSearchIcon={false}
                                countryCodeEditable={false}
                                country={"us"}
                                onlyCountries={["us", "ca"]}
                                value={phone}
                                onChange={(value) => setPhone(value)}
                              />
                            </div>
                            <div id="w-node-_9ac491eb-5823-37d9-0436-c007130d9269-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>City</strong>
                              </p>
                              <input
                                className="text-field-2 w-input"
                                maxLength="256"
                                name="field-8"
                                data-name="Field 8"
                                placeholder={user.city}
                                type="text"
                                id="field-8"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </div>
                            <div id="w-node-fc1236cc-2d12-c1bc-bb69-d93ac1ffe3fb-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>Type</strong>
                              </p>
                              <select
                                id="field-9"
                                name="field-9"
                                data-name="Field 9"
                                className="text-field-2 w-select"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                              >
                                <option value=""></option>
                                <option value="CUSTOMER">Customer</option>
                                <option value="ADMIN">Admin</option>
                                <option value="TECHNICIAN">Technician</option>
                                <option value="RETAIL">Retail</option>
                              </select>
                            </div>
                            <div id="w-node-e7780b41-52de-b23e-a030-bffddc8e1e6c-6400d267">
                              <p className="paragraph-2 _16">
                                <strong>Registration Code</strong>
                              </p>
                              <input
                                className="text-field-2 w-input"
                                maxLength="256"
                                name="Registration-Code"
                                data-name="Registration Code"
                                placeholder={user.registrationCode}
                                type="text"
                                id="Registration-Code"
                                value={registrationCode}
                                onChange={(e) =>
                                  setRegistrationCode(e.target.value)
                                }
                              />
                            </div>
                          </form>
                          <div className="w-form-done">
                            <div>
                              Thank you! Your submission has been received!
                            </div>
                          </div>
                          <div className="w-form-fail">
                            <div>
                              Oops! Something went wrong while submitting the
                              form.
                            </div>
                          </div>
                        </div>
                      </div>
                      {!!deleteError && (
                        <div className="hide-block mb-10">
                          <div className="invalid-box">
                            {typeof deleteError === "string" && (
                              <p className="paragraph-2 _16">
                                <strong>{deleteError}</strong>
                              </p>
                            )}
                            {typeof deleteError === "object" && (
                              <ul className="mb-0">
                                {deleteError.map((errorItem, index) => (
                                  <li key={index} className="paragraph-2 _16">
                                    <strong>{errorItem}</strong>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="button-div right-align">
                    {!(
                      (user.role === "SUPERADMIN" &&
                        adminUser.role === "ADMIN") ||
                      user.role === adminUser.role
                    ) && (
                      <button
                        type="button"
                        data-w-id="9518481e-e590-5721-9fe7-9f71491b4f33"
                        className="button-cancel w-button"
                        onClick={handleDeleteUser}
                        disabled={isDeleting}
                      >
                        Delete User
                        {isDeleting && (
                          <div
                            className="spinner-border ms-10"
                            role="status"
                          ></div>
                        )}
                      </button>
                    )}
                    <button
                      type="button"
                      data-w-id="9518481e-e590-5721-9fe7-9f71491b4f33"
                      className="button-cancel w-button"
                      onClick={handleResetFields}
                    >
                      Cancel
                    </button>
                    {!(
                      (user.role === "SUPERADMIN" &&
                        adminUser.role === "ADMIN") ||
                      user.role === adminUser.role
                    ) && (
                      <button
                        type="button"
                        className="button-main w-button"
                        disabled={isLoading}
                        onClick={handleChange}
                      >
                        Apply Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {(user.role === "TECHNICIAN" ||
                user.role === "CUSTOMER" ||
                user.role === "RETAIL") && (
                <div className="list">
                  <div className="heading-div no-margin">
                    <h3 className="heading-style-3">Bookings</h3>
                  </div>
                  {bookings.map((booking) => (
                    <AdminBooking
                      key={booking.id}
                      booking={booking}
                      handleCancel={() => handleCancelBooking(booking.id)}
                    />
                  ))}
                  {!isLoading && bookings.length === 0 && (
                    <NoItems description="No bookings found for this customer." />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className="lightbox"
        style={{
          display: showRemoveDialog ? "flex" : "none",
        }}
      >
        <div className="content-box-large lightbox-content">
          <div>
            <p className="paragraph-2 _16">
              Are you sure you would like to delete this service?
            </p>
            <div className="button-div full-width">
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b4"
                className="button-main full-width w-button"
                onClick={() => setShowRemoveDialog(false)}
              >
                Back
              </button>
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b6"
                className="button-cancel delete full-width w-button"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="lightbox"
        style={{
          display: showRemoveUserDialog ? "flex" : "none",
        }}
      >
        <div className="content-box-large lightbox-content">
          <div>
            <p className="paragraph-2 _16">
              Are you sure you would like to delete this user?
            </p>
            <div className="button-div full-width">
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b4"
                className="button-main full-width w-button"
                onClick={() => setShowRemoveUserDialog(false)}
              >
                Back
              </button>
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b6"
                className="button-cancel delete full-width w-button"
                onClick={handleConfirmDeleteUser}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithNavbar>
  );
};

export default ViewUser;

import { useState } from "react";
import { getMyBookings, removeABooking } from "../../services/booking";
import { useAuthContext } from "../useAuthContext";
import { getServicesFromIds } from "../../services";

export const useMyBooking = () => {
  const { accessToken } = useAuthContext();
  const [gettingError, setGettingError] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);

  const getBookings = async (q = "", qDate = "") => {
    setIsLoading(true);
    const response = await getMyBookings(accessToken, q, qDate);
    const json = await response.json();

    if (response.ok) {
      for (let i = 0; i < json.length; i++) {
        const serviceIds = json[i].services.map(item  => parseInt(item.serviceId));

        const servicesResponse = await getServicesFromIds({ids: serviceIds}, accessToken);
        const servicesJson = await servicesResponse.json();

        if (servicesResponse.ok) {
          json[i].services = servicesJson;
        }
      }
      setIsLoading(false);
      return json;
    }

    if (!response.ok) {
      setIsLoading(false);
      setGettingError(json.message);
      return [];
    }
  };

  const deleteBooking = async (bookingId) => {
    const response = await removeABooking(bookingId, accessToken);
    const json = await response.json();
    console.log(json)

    return response.ok;
  }

  return { getBookings, gettingError, isLoading, deleteBooking };
};

import { useState } from "react";
import { postForgotPassword } from "../../services";

export const useForgotPassword = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const forgotPassword = async (email) => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    const response = await postForgotPassword(email);
    const json = await response.json();

    if (response.ok) {
      setIsLoading(false);
      setSuccess(true);
      setError(null);
      return json;
    }

    if (!response.ok) {
      setIsLoading(false);
      setError(json.message);
      setSuccess(null);
      return;
    }
  };

  return { forgotPassword, success, error, isLoading };
};

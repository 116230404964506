import { useState } from "react";
import { createAStore, getAllStores, updateStore, getAStore, deleteStore } from "../../services";
import { useAuthContext } from "../useAuthContext";

export const useAdminStores = () => {
  const { accessToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [gettingError, setGettingError] = useState(null);
  const [createError, setCreateError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [editError, setEditError] = useState(null);
  const [removeError, setRemoveError] = useState(null);

  const getStores = async (q = "") => {
    setIsLoading(true);

    const response = await getAllStores(accessToken, q);
    const json = await response.json();

    if (response.ok) {
      setIsLoading(false);
      return json;
    }

    if (!response.ok) {
      setIsLoading(false);
      setGettingError(json.message);
      return [];
    }
  };

  const createStore = async (data) => {
    setIsCreating(true);

    const response = await createAStore(data, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsCreating(false);
      setCreateError(null);
      return json;
    }

    if (!response.ok) {
      setIsCreating(false);
      setCreateError(json.message);
      return;
    }
  };

  const editStore = async (storeId, storeData) => {
    let data = {};

    if (
      !storeData.displayName &&
      !storeData.carter &&
      !storeData.number &&
      !storeData.street &&
      !storeData.city &&
      !storeData.state &&
      !storeData.zip &&
      storeData.contact === "+1" &&
      !storeData.registrationCode
    ) {
      setEditError("All fields are empty.");
      return;
    }

    if (!!storeData.displayName) {
      data["displayName"] = storeData.displayName;
    }

    if (!!storeData.carter) {
      data["carter"] = storeData.carter;
    }

    if (!!storeData.number) {
      data["number"] = storeData.number;
    }

    if (!!storeData.street) {
      data["street"] = storeData.street;
    }

    if (!!storeData.city) {
      data["city"] = storeData.city;
    }

    if (!!storeData.state) {
      data["state"] = storeData.state;
    }

    if (!!storeData.zip) {
      data["zip"] = parseInt(storeData.zip);
    }

    if (storeData.contact !== "+1") {
      data["contact"] = storeData.contact;
    }

    if (!!storeData.registrationCode) {
      data["registrationCode"] = storeData.registrationCode;
    }

    const response = await updateStore(storeId, data, accessToken);
    const json = await response.json();

    if (response.ok) {
      setEditError(null);
      return json;
    }

    if (!response.ok) {
      setEditError(json.message);
      return;
    }
  };

  const getOneStore = async (storeId) => {
    const response =  await getAStore(storeId, accessToken);
    const json = await response.json();

    if (response.ok) {
      return json;
    }

    if (!response.ok) {
      return;
    }
  }

  const removeStore = async (storeId) => {
    const response = await deleteStore(storeId, accessToken);
    const json = await response.json();

    if (response.ok) {
      setRemoveError(null);
      return json;
    }

    if (!response.ok) {
      setRemoveError(json.message);
      return;
    }
  };

  return {
    getStores,
    isLoading,
    gettingError,
    createStore,
    isCreating,
    createError, 
    setCreateError, 
    editStore,
    editError, 
    setEditError, 
    getOneStore,  
    removeError, 
    removeStore, 
  };
};

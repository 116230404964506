import { BOOKING_COMMENTS_URL } from "../common/constants";

export const createBookingComment = async (data, accessToken) => {
  return await fetch(BOOKING_COMMENTS_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const getCommentsOfABooking = async (bookingId, accessToken) => {
  return await fetch(BOOKING_COMMENTS_URL + `/ofABooking/${bookingId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
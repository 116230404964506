import { useState } from "react";
import { updateMe } from "../../services";
import { useAuthContext } from "../useAuthContext";
import { AuthActionType } from "../../common/types";

export const useUpdateMyProfile = () => {
  const { authDispatch, accessToken } = useAuthContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateMyProfile = async (profileData) => {
    setIsLoading(true);
    let data = {};

    if (
      !profileData.firstName &&
      !profileData.lastName &&
      !profileData.email &&
      !profileData.address &&
      !profileData.city &&
      !profileData.state &&
      !profileData.zip &&
      (profileData.phone === "+1")
    ) {
      setError("All fields are empty.");
      setSuccess(null);
      setIsLoading(false);
      return;
    }

    if (!!profileData.firstName) {
      data["firstName"] = profileData.firstName;
    }

    if (!!profileData.lastName) {
      data["lastName"] = profileData.lastName;
    }

    if (!!profileData.email) {
      data["email"] = profileData.email;
    }

    if (!!profileData.address) {
      data["address"] = profileData.address;
    }

    if (!!profileData.city) {
      data["city"] = profileData.city;
    }

    if (!!profileData.state) {
      data["state"] = profileData.state;
    }

    if (!!profileData.zip) {
      data["zip"] = parseInt(profileData.zip);
    }

    if (profileData.phone !== "+1") {
      data["phone"] = profileData.phone;
    }

    const response = await updateMe(data, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsLoading(false);
      setSuccess("Successfully Changed.");
      setError(null);
      authDispatch({
        type: AuthActionType.SETUSER,
        payload: {
          user: json,
        },
      });
    }

    if (!response.ok) {
      if (response.status === 404) {
        setError("Cannot connect to server");
      } else {
        setError(json.message);
      }
      setSuccess(null);
      setIsLoading(false);
    }
  };

  const clearErrorAndSuccess = () => {
    setError(null);
    setSuccess(null);
  }

  return { updateMyProfile, isLoading, error, success, clearErrorAndSuccess };
};

import StoreCheckItem from "../../../components/StoreCheckItem/StoreCheckItem";

const Slide2 = ({
  slideWidth,
  slideIndex,
  stores, 
  selectedStore, 
  isGettingStores,
  handleStoreClick, 
  handlePrevious, 
  handleNext
}) => {
  return (
    <div
      className="slide-1 w-slide"
      style={{
        transform: `translateX(${0 - slideWidth * slideIndex}px)`,
        opacity: 0,
      }}
    >
      <div className="form-fields">
        <h2 className="heading-2">Choose your preferred location.</h2>
        <div className="spacer"></div>
        <p className="paragraph-2 _16">
          These are the stores closest to your address.
        </p>
        <div className="spacer"></div>
        {isGettingStores && (
          <img
            src={require("../../../common/assets/images/loading-buffering.gif")}
            width="100"
            height="100"
            loading="lazy"
            alt=""
            className="margin-auto mt-20 mb-20"
          />
        )}
        {!isGettingStores && (
          <div className="form-item-wrapper">
            {stores.map((store) => (
              <StoreCheckItem
                key={store.id}
                store={store}
                selectedStore={selectedStore}
                handleStoreClick={() => handleStoreClick(store)}
              />
            ))}
          </div>
        )}
        <div className="spacer"></div>
        <div className="slider-buttons">
          <button
            type="button"
            className="slider-left w-inline-block"
            disabled={isGettingStores}
            onClick={handlePrevious}
          >
            <img
              alt=""
              src="https://uploads-ssl.webflow.com/66143990c6c4610f2b2b217a/66143990c6c4610f2b2b224e_arrow.svg"
              className="arrow"
            />
          </button>
          <button
            type="button"
            className="slider-right w-button"
            disabled={isGettingStores}
            onClick={handleNext}
          >
            Next Step
          </button>
        </div>
        <div className="slide-counter">
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Slide2;

import ZipCodeInput from "../../../components/ZipCodeInput/ZipCodeInput";

const Slide1 = ({
  slideWidth,
  slideIndex,
  address1,
  setAddress1,
  address2,
  setAddress2,
  city,
  setCity,
  state,
  setState,
  zip,
  setZip,
  handleNext,
}) => {
  return (
    <div
      className="slide-1 w-slide"
      style={{
        transform: `translateX(${0 - slideWidth * slideIndex}px)`,
        opacity: 1,
      }}
    >
      <div className="form-fields">
        <h2 className="heading-2">Let&#x27;s start with your location.</h2>
        <div className="spacer"></div>
        <p className="paragraph-2 _16">
          Please fill in the details below so we can get started with your bike
          repair.
        </p>
        <div className="spacer"></div>
        <div className="form-field__wrap">
          <label htmlFor="Location" className="field-label-3">
            Street Address
          </label>
          <input
            className="text-field-2 w-input"
            maxLength="256"
            name="Location"
            data-name="Location"
            placeholder="Enter your street address"
            type="text"
            id="Location"
            required=""
            value={address1}
            onChange={(e) => {
              setAddress1(e.target.value);
            }}
          />
          <input
            className="text-field-2 w-input"
            maxLength="256"
            name="Location-2"
            data-name="Location 2"
            placeholder="Apt., suite or unit #"
            type="text"
            id="Location-2"
            value={address2}
            onChange={(e) => {
              setAddress2(e.target.value);
            }}
          />
        </div>
        <div className="form-field__wrap">
          <label htmlFor="Location-3" className="field-label-3">
            City
          </label>
          <input
            className="text-field-2 w-input"
            maxLength="256"
            name="Location-3"
            data-name="Location 3"
            placeholder="Enter your city"
            type="text"
            id="Location-3"
            required=""
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>
        <div className="form-field__wrap margin">
          <div className="_2-col-grid">
            <div id="w-node-f9ac2cdd-07e7-1dfb-df2c-ee37f04aaf71-34466521">
              <label htmlFor="Location-3" className="field-label-3">
                State
              </label>
              <input
                className="text-field-2 w-input"
                maxLength="256"
                name="Location-3"
                data-name="Location 3"
                placeholder="CA"
                type="text"
                id="Location-3"
                required=""
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />
            </div>
            <div id="w-node-_16fd9fef-2765-10fe-7697-4e29ab3ca293-34466521">
              <label htmlFor="Location-3" className="field-label-3">
                ZIP Code
              </label>
              <ZipCodeInput
                classes={"text-field-2 w-input"}
                placeholder={"94043"}
                zip={zip}
                setZip={setZip}
              />
            </div>
          </div>
        </div>
        <div className="slider-buttons">
          <button
            type="button"
            className="slider-right w-button"
            onClick={handleNext}
          >
            Get Started
          </button>
        </div>
        <div className="slide-counter">
          <div className="dot active-dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Slide1;

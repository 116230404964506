import { GET_NEAR_STORES_URL, GET_NEAR_STORES_WITHOUT_TOKEN_URL, STORES_URL } from "../common/constants";

export const getNearStores = async (location, accessToken) => {
  return await fetch(GET_NEAR_STORES_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ location }),
  });
};

export const getNearStoresWithoutToken = async (location) => {
  return await fetch(GET_NEAR_STORES_WITHOUT_TOKEN_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ location }),
  });
};

export const getAllStores = async (accessToken, qString) => {
  return await fetch(STORES_URL + "?q=" + qString, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getAStore = async (storeId, accessToken) => {
  return await fetch(STORES_URL + "/" + storeId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createAStore = async (data, accessToken) => {
  return await fetch(STORES_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const updateStore = async (storeId, data, accessToken) => {
  return await fetch(STORES_URL + "/" + storeId, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const deleteStore = async (storeId, accessToken) => {
  return await fetch(STORES_URL + "/" + storeId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

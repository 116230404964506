import React, { useState } from "react";
import { useLogin } from "../../hooks/auth/useLogin";

import "./Login.scss";

const LogIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();

  const verifiedLogin = localStorage.getItem("verifiedLogin") === "true";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await login(email.toLowerCase(), password);
    if (result) {
      localStorage.setItem("verifiedLogin", false);
    }
  };

  return (
    <section className="section-2">
      <div className="wrapper-2">
        <div className="left">
          <div className="navigation w-clearfix">
            <a href="/" className="logo-link w-inline-block">
              <img
                src={
                  require("../../common/assets/images/horizontal-on-white.svg")
                    .default
                }
                loading="lazy"
                width="182"
                alt=""
                className="logo-2"
              />
            </a>
          </div>
          <div className="form-wrapper">
            <div className="form-block-3 w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                data-ms-form="login"
                className="form-2"
                data-wf-page-id="66104ccbdeedd600c49fd3a2"
                data-wf-element-id="291f4375-cf0d-94d8-f385-c09062107b1d"
                onSubmit={handleSubmit}
              >
                <h1 className="heading-style-1">Welcome</h1>
                <p className="paragraph-2 _16">
                  Get back on the road with expert repairs and maintenance.
                </p>
                {verifiedLogin && (
                  <div className="hide-block">
                    <div className="resent-block">
                      <p className="paragraph-2 _16">
                        <strong>
                          Thank you for verifying your email, please Log In to
                          continue.
                        </strong>
                      </p>
                    </div>
                  </div>
                )}
                <div className="spacer"></div>
                <div className="form-field__wrap margin">
                  <label htmlFor="email" className="field-label-3">
                    Email
                  </label>
                  <input
                    className="text-field-2 w-input"
                    maxLength="256"
                    name="email"
                    data-name="email"
                    placeholder="hi@example.com"
                    type="text"
                    id="email"
                    required=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-field__wrap mb-30">
                  <label htmlFor="password" className="field-label-3">
                    Password
                  </label>
                  <div className="pw-wrap">
                    <input
                      className="text-field-2 w-input"
                      maxLength="256"
                      name="password"
                      data-name="password"
                      placeholder="Enter Password"
                      data-w-id="f80f1e5e-f45a-bc26-4102-b24c1f4081bf"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      required=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      data-w-id="291f4375-cf0d-94d8-f385-c09062107b2b"
                      className="eye-wrap"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? (
                        <img
                          src={
                            require("../../common/assets/images/eye-open.svg")
                              .default
                          }
                          loading="lazy"
                          alt=""
                          id="eye-open"
                          className="eye-icon open"
                        />
                      ) : (
                        <img
                          src={
                            require("../../common/assets/images/eye-closed.svg")
                              .default
                          }
                          loading="lazy"
                          alt=""
                          id="eye-close"
                          className="eye-icon closed"
                        />
                      )}
                    </div>
                  </div>
                  {!!error && (
                    <div className="hide-block">
                      <div className="invalid-box">
                        {typeof error === "string" && (
                          <p className="paragraph-2 _16">
                            <strong>{error}</strong>
                          </p>
                        )}
                        {typeof error === "object" && (
                          <ul className="mb-0">
                            {error.map((errorItem, index) => (
                              <li key={index} className="paragraph-2 _16">
                                <strong>{errorItem}</strong>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                  <a
                    href="/forgot-password"
                    className="link-text-button center w-inline-block"
                  >
                    <div>Forgot password?</div>
                  </a>
                </div>
                <button
                  type="submit"
                  className="button-normal w-button"
                  disabled={isLoading}
                >
                  Log In
                  {isLoading && (
                    <div className="spinner-border ms-10" role="status"></div>
                  )}
                </button>
                <p className="paragraph-2 _16">
                  Don&#x27;t have an account? 
                  <a href="/signup" className="link-text-button">
                    <span>Sign Up</span>
                  </a>
                </p>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-wrap">
            <img
              src={require("../../common/assets/images/Teach.jpg")}
              loading="lazy"
              alt=""
              className="radius-10"
            />
            <div className="text-wrap">
              <p className="p-12">
                By signing up, you agree to National Assemblers&#x27;{" "}
                <a href="/" className="link-text-button">
                  Terms of Service
                </a>{" "}
                &amp;{" "}
                <a href="/" className="link-text-button">
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogIn;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-box-large .show {
  transition: all 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminService/AdminService.scss"],"names":[],"mappings":"AACC;EACC,oBAAA;AAAF","sourcesContent":[".content-box-large {\n\t.show {\n\t\ttransition: all .2s;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

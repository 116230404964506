import { useState } from "react";
import { useAuthContext } from "../useAuthContext";
import { AuthActionType } from "../../common/types";

import { getMe, postLogin } from "../../services";

export const useLogin = () => {
  const { authDispatch } = useAuthContext();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const login = async (email, password) => {
    setIsLoading(true);
    const response = await postLogin(email, password);

    const json = await response.json();

    if (response.ok) {
      const accessToken = json.access_token;

      const getMeResponse = await getMe(accessToken);

      const user = await getMeResponse.json();

      localStorage.setItem(
        "bikeRepairAuth",
        JSON.stringify({
          user,
          accessToken,
        })
      );

      authDispatch({
        type: AuthActionType.LOGIN,
        payload: {
          user,
          accessToken,
        },
      });

      setIsLoading(false);
      return user;
    }

    if (!response.ok) {
      if (response.status === 404) {
        setError("Cannot connect to server");
      } else {
        setError(json.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return { login, error, isLoading };
};

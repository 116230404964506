import moment from "moment";
import { formatPhoneNumber } from "react-phone-number-input";

const Slide7 = ({
  slideWidth,
  slideIndex,
  contactFirstName,
  contactLastName,
  contactEmail,
  contactPhone,
  selectedServices,
  otherServiceClicked,
  otherServiceContent,
  bikeMake,
  bikeModel,
  selectedStore,
  dropOffScheduleDate,
  dropOffScheduleTime,
  error,
  isLoading,
  handlePrevious,
  handleSubmit,
}) => {
  return (
    <div
      className="slide-1 w-slide"
      style={{
        transform: `translateX(${0 - slideWidth * slideIndex}px)`,
        opacity: 0,
      }}
    >
      <div className="form-fields">
        <h2 className="heading-2">Summary</h2>
        <div className="spacer"></div>
        <p className="paragraph-2 _16">
          Please make sure all booking information below is correct.
        </p>
        <div className="spacer"></div>
        <div className="form-field__wrap">
          <div className="_2-col-grid">
            <div id="w-node-_6840948b-61cc-9f30-913a-3be7fedbc67c-34466521">
              <label htmlFor="First-Name-2" className="field-label-3">
                First Name
              </label>
              <p className="paragraph-2 _16">{contactFirstName}</p>
            </div>
            <div id="w-node-_6840948b-61cc-9f30-913a-3be7fedbc680-34466521">
              <label htmlFor="Last-Name-2" className="field-label-3">
                Last Name
              </label>
              <p className="paragraph-2 _16">{contactLastName}</p>
            </div>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="form-field__wrap">
          <label htmlFor="Email-Address-2" className="field-label-3">
            Email
          </label>
          <p className="paragraph-2 _16">{contactEmail}</p>
        </div>
        <div className="spacer"></div>
        <div className="form-field__wrap margin">
          <label htmlFor="Phone-Number-2" className="field-label-3">
            Phone Number
          </label>
          <p className="paragraph-2 _16">
            {formatPhoneNumber(
              contactPhone.includes("+") ? contactPhone : "+" + contactPhone
            )}
          </p>
        </div>
        <div className="divider margin-20"></div>
        <div className="form-field__wrap">
          <label htmlFor="Phone-Number-2" className="field-label-3">
            Service(s)
          </label>
          {selectedServices.map((service) => (
            <p key={service.id} className="paragraph-2 _16">
              {service.name}
            </p>
          ))}
          {otherServiceClicked && (
            <p className="paragraph-2 _16">
              Other / I&#x27;m Not Sure: {otherServiceContent}
            </p>
          )}
        </div>
        <div className="spacer"></div>
        <div className="form-field__wrap">
          <div className="_2-col-grid">
            <div id="w-node-e9ad3585-100e-d4ba-c7ed-8c42fe1d6814-34466521">
              <label htmlFor="First-Name-2" className="field-label-3">
                Make
              </label>
              <p className="paragraph-2 _16">{bikeMake}</p>
            </div>
            <div id="w-node-e9ad3585-100e-d4ba-c7ed-8c42fe1d6819-34466521">
              <label htmlFor="Last-Name-2" className="field-label-3">
                Model
              </label>
              <p className="paragraph-2 _16">{bikeModel}</p>
            </div>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="divider margin-20"></div>
        {!!selectedStore && (
          <div className="form-field__wrap">
            <label htmlFor="Phone-Number-2" className="field-label-3">
              Service Location
            </label>
            <p className="paragraph-2 _16">{selectedStore.displayName}</p>
            <p className="paragraph-2 _16">
              {selectedStore.street}
              <br />
              {selectedStore.city}, {selectedStore.state} {selectedStore.zip}
            </p>
          </div>
        )}
        <div className="spacer"></div>
        <div className="form-field__wrap margin">
          <label htmlFor="Phone-Number-2" className="field-label-3">
            Date &amp; Time
          </label>
          <p className="paragraph-2 _16">
            {moment(
              moment(dropOffScheduleDate, "YYYY-MM-DD h:mm A").format(
                "YYYY-MM-DD"
              ) +
                " " +
                dropOffScheduleTime,
              "YYYY-MM-DD h:mm A"
            ).format("MMMM DD, YYYY")}{" "}
            -{" "}
            {moment(
              moment(dropOffScheduleDate, "YYYY-MM-DD h:mm A").format(
                "YYYY-MM-DD"
              ) +
                " " +
                dropOffScheduleTime,
              "YYYY-MM-DD h:mm A"
            ).format("h:mm a")}{" "}
            to{" "}
            {moment(
              moment(dropOffScheduleDate, "YYYY-MM-DD h:mm A").format(
                "YYYY-MM-DD"
              ) +
                " " +
                dropOffScheduleTime,
              "YYYY-MM-DD h:mm A"
            )
              .add(1, "hours")
              .format("h:mm a")}
          </p>
        </div>
        <div className="divider margin-20"></div>
        {!!error && (
          <div className="hide-block mb-10">
            <div className="invalid-box">
              {typeof error === "string" && (
                <p className="paragraph-2 _16">
                  <strong>{error}</strong>
                </p>
              )}
              {typeof error === "object" && (
                <ul className="mb-0">
                  {error.map((errorItem, index) => (
                    <li key={index} className="paragraph-2 _16">
                      <strong>{errorItem}</strong>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
        <div className="slider-buttons">
          <button
            type="button"
            className="slider-left w-inline-block"
            disabled={isLoading}
            onClick={handlePrevious}
          >
            <img
              alt=""
              src="https://uploads-ssl.webflow.com/66143990c6c4610f2b2b217a/66143990c6c4610f2b2b224e_arrow.svg"
              className="arrow"
            />
          </button>
          <button
            type="submit"
            className="slider-right w-button"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Submit Booking
            {isLoading && (
              <div className="spinner-border ms-10" role="status"></div>
            )}
          </button>
        </div>
        <div className="slide-counter">
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Slide7;

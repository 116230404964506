import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Slide6 = ({
  slideWidth,
  slideIndex,
  contactFirstName,
  setContactFirstName,
  contactLastName,
  setContactLastName,
  contactEmail,
  setContactEmail,
  contactPhone,
  setContactPhone,
  handlePrevious,
  handleNext,
}) => {
  return (
    <div
      className="slide-1 w-slide"
      style={{
        transform: `translateX(${0 - slideWidth * slideIndex}px)`,
        opacity: 0,
      }}
    >
      <div className="form-fields">
        <h2 className="heading-2">How do we get a hold of you?</h2>
        <div className="spacer"></div>
        <p className="paragraph-2 _16">
          Please fill in your contact information. Your phone number will be
          used for SMS notifications.
        </p>
        <div className="spacer"></div>
        <div className="form-field__wrap">
          <div className="_2-col-grid">
            <div id="w-node-_26b2ed9f-b5e3-ab07-ced4-60213b0d4dc3-34466521">
              <label htmlFor="First-Name" className="field-label-3">
                First Name
              </label>
              <input
                className="text-field-2 w-input"
                maxLength="256"
                name="First-Name"
                data-name="First Name"
                placeholder="First Name"
                type="text"
                id="First-Name"
                required=""
                value={contactFirstName}
                onChange={(e) => setContactFirstName(e.target.value)}
              />
            </div>
            <div id="w-node-_26b2ed9f-b5e3-ab07-ced4-60213b0d4dc7-34466521">
              <label htmlFor="Last-Name" className="field-label-3">
                Last Name
              </label>
              <input
                className="text-field-2 w-input"
                maxLength="256"
                name="Last-Name"
                data-name="Last Name"
                placeholder="Last Name"
                type="text"
                id="Last-Name"
                required=""
                value={contactLastName}
                onChange={(e) => setContactLastName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-field__wrap">
          <label htmlFor="Email-Address" className="field-label-3">
            Email
          </label>
          <input
            className="text-field-2 w-input"
            maxLength="256"
            name="Email-Address"
            data-name="Email Address"
            placeholder="Enter your email address"
            type="text"
            id="Email-Address"
            required=""
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
        </div>
        <div className="form-field__wrap margin">
          <label htmlFor="Location-3" className="field-label-3">
            Phone Number
          </label>
          <PhoneInput
            containerClass="text-field-2"
            inputClass="text-field-2 w-input w-100 h-100"
            dropdownClass="d-none"
            buttonClass="d-none"
            disableSearchIcon={false}
            countryCodeEditable={false}
            country={"us"}
            onlyCountries={["us", "ca"]}
            value={contactPhone}
            onChange={(value) => setContactPhone(value)}
          />
        </div>
        <div className="slider-buttons">
          <button
            type="button"
            className="slider-left w-inline-block"
            onClick={handlePrevious}
          >
            <img
              alt=""
              src="https://uploads-ssl.webflow.com/66143990c6c4610f2b2b217a/66143990c6c4610f2b2b224e_arrow.svg"
              className="arrow"
            />
          </button>
          <button
            type="button"
            className="slider-right w-button"
            onClick={handleNext}
          >
            Next Step
          </button>
        </div>
        <div className="slide-counter">
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Slide6;

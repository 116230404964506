import React, { useEffect, useState } from "react";
import LayoutWithNavbar from "../../components/Layouts/LayoutWithNavbar/LayoutWithNavbar";
import Booking from "../../components/Booking/Booking";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useMyBooking } from "../../hooks/booking/useMyBooking";
import NoItems from "../../components/NoItems/NoItems";

const Bookings = () => {
  const { user } = useAuthContext();
  const [bookings, setBookings] = useState([]);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const { getBookings, gettingError, isLoading, deleteBooking } =
    useMyBooking();

  const handleCancelBooking = (bookingId) => {
    setSelectedId(bookingId);
    setShowRemoveDialog(true);
  };

  const handleConfirmDelete = async () => {
    const deleteResult = await deleteBooking(selectedId);

    if (deleteResult) {
      setBookings(bookings.filter((booking) => booking.id !== selectedId));
    }

    setShowRemoveDialog(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const bookings = await getBookings();
    setBookings(bookings);
  };

  return (
    <LayoutWithNavbar>
      <div className="section-main">
        <div className="w-layout-blockcontainer container small w-container">
          <div className="list">
            <div className="heading-div no-margin">
              <h6 className="subheading">Welcome {user.firstName},</h6>
              <h2 className="heading-style-2">Your Bookings</h2>
            </div>
            <div>
              <a href="/repair" className="button-main book-button w-button">
                Create New Booking
              </a>
            </div>
            {isLoading && (
              <img
                src={require("../../common/assets/images/loading-buffering.gif")}
                width="100"
                height="100"
                loading="lazy"
                alt=""
                className="margin-auto mt-20 mb-20"
              />
            )}
            {!!gettingError && (
              <div className="hide-block mb-10">
                <div className="invalid-box">
                  <p className="paragraph-2 _16">
                    <strong>{gettingError}</strong>
                  </p>
                </div>
              </div>
            )}
            {bookings.map((booking) => (
              <Booking
                key={booking.id}
                booking={booking}
                handleCancel={(bookingId) => handleCancelBooking(bookingId)}
              />
            ))}
            {!isLoading && bookings.length === 0 && (
              <NoItems description="No bookings found." />
            )}
          </div>
        </div>
      </div>
      <div
        className="lightbox"
        style={{
          display: showRemoveDialog ? "flex" : "none",
        }}
      >
        <div className="content-box-large lightbox-content">
          <div>
            <p className="paragraph-2 _16">
              Are you sure you would like to delete this service?
            </p>
            <div className="button-div full-width">
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b4"
                className="button-main full-width w-button"
                onClick={() => setShowRemoveDialog(false)}
              >
                Back
              </button>
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b6"
                className="button-cancel delete full-width w-button"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithNavbar>
  );
};

export default Bookings;

import { AuthActionType } from "../../common/types";
import { useAuthContext } from "../useAuthContext";

export const useLogout = () => {
  const { authDispatch } = useAuthContext();

  const logout = () => {
    localStorage.removeItem("bikeRepairAuth");
    authDispatch({ type: AuthActionType.LOGOUT });
  };

  return { logout };
};

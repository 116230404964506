import ServiceRadioItem from "../../../components/ServiceRadioItem/ServiceRadioItem";

const Slide5 = ({
  slideWidth,
  slideIndex,
  services,
  isGettingServices,
  selectedServices,
  handleServiceClick,
  otherServiceClicked,
  setOtherServiceClicked,
  otherServiceContent,
  setOtherServiceContent,
  handlePrevious,
  handleNext,
}) => {
  return (
    <div
      className="slide-1 w-slide"
      style={{
        transform: `translateX(${0 - slideWidth * slideIndex}px)`,
        opacity: 0,
      }}
    >
      <div className="form-fields">
        <h2 className="heading-2">What service type can we help you with?</h2>
        <div className="spacer"></div>
        <p className="paragraph-2 _16">
          Please fill in the details below so we can know exactly what bike
          repair service you need.
        </p>
        <div className="spacer"></div>
        <div className="form-item-wrapper _10">
          {!isGettingServices &&
            services.map((service) => (
              <ServiceRadioItem
                key={service.id}
                service={service}
                selectedServices={selectedServices}
                handleServiceClick={handleServiceClick}
              />
            ))}
          <div
            className={`w-checkbox checkbox-field-2 checkbox-base ${
              otherServiceClicked ? "store-checked" : ""
            }`}
            onClick={() => setOtherServiceClicked(!otherServiceClicked)}
          >
            <input
              id="New Business"
              name="New-Business"
              data-name="New Business"
              type="checkbox"
              data-w-id="b515935e-fe32-1ab5-9eb6-1470bf6a0f0d"
              className="w-checkbox-input checkbox"
            />
            <div className="checkbox-text-wrapper pointer">
              <h4 className="checkbox-h4">Other / I&#x27;m Not Sure</h4>
            </div>
            <span
              htmlFor="New-Business"
              className="checkbox-title w-form-label"
            >
              Yes!
            </span>
          </div>
          <div
            style={{
              display: otherServiceClicked ? "block" : "none",
              opacity: otherServiceClicked ? 1 : 0,
              height: otherServiceClicked ? "auto" : 0,
            }}
            className="div-other"
          >
            <textarea
              placeholder="Tell us more..."
              maxLength="5000"
              id="Other"
              name="Other"
              data-name="Other"
              className="text-box w-input"
              value={otherServiceContent}
              onChange={(e) => setOtherServiceContent(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="slider-buttons">
          <button
            type="button"
            className="slider-left w-inline-block"
            onClick={handlePrevious}
          >
            <img
              alt=""
              src="https://uploads-ssl.webflow.com/66143990c6c4610f2b2b217a/66143990c6c4610f2b2b224e_arrow.svg"
              className="arrow"
            />
          </button>
          <button
            type="button"
            className="slider-right w-button"
            onClick={handleNext}
          >
            Next Step
          </button>
        </div>
        <div className="slide-counter">
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Slide5;

import React, { useEffect, useState } from "react";
import AdminBooking from "../../components/AdminBooking/AdminBooking";
import LayoutWithNavbar from "../../components/Layouts/LayoutWithNavbar/LayoutWithNavbar";
import { useParams } from "react-router-dom";
import { useStoreBookings } from "../../hooks/booking/useStoreBookings";
import { useAdminStores } from "../../hooks/admin/useAdminStores";
import { useMyBooking } from "../../hooks/booking/useMyBooking";
import { useAuthContext } from "../../hooks/useAuthContext";
import NoItems from "../../components/NoItems/NoItems";
import moment from "moment";

const StoreBookings = () => {
  const { user } = useAuthContext();
  const { storeId } = useParams();
  const { getBookings, isGetting, gettingError } = useStoreBookings();
  const { getOneStore } = useAdminStores();
  const { deleteBooking } = useMyBooking();
  const [bookings, setBookings] = useState([]);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [search, setSearch] = useState("");
	const [store, setStore] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const bookingsResult = await getBookings(storeId);

    if (bookingsResult) {
      setBookings(bookingsResult);
    }

		const storeResult = await getOneStore(storeId);

		if (storeResult) {
			setStore(storeResult);
		}
  };

  const handleCancelBooking = (bookingId) => {
    setSelectedId(bookingId);
    setShowRemoveDialog(true);
  };

  const handleConfirmDelete = async () => {
    const deleteResult = await deleteBooking(selectedId);

    if (deleteResult) {
      setBookings(bookings.filter((booking) => booking.id !== selectedId));
    }

    setShowRemoveDialog(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      getData();
    }
  }

  const handleFilter = async (e) => {
    e.preventDefault();

    let bookingsResult;
    if (!!Date.parse(search)) {
      bookingsResult = await getBookings(
        storeId, 
        search,
        moment(new Date(search)).toISOString()
      );
    } else {
      bookingsResult = await getBookings(storeId, search);
    }

    if (bookingsResult) {
      setBookings(bookingsResult);
    }
  };

  return (
    <LayoutWithNavbar>
      <div className="section-main">
        <div className="w-layout-blockcontainer container small w-container">
          <div className="list">
            <div className="heading-div no-margin">
              <h6 className="subheading">Welcome {user.firstName},</h6>
              <h2 className="heading-style-2">Walmart #{store?.number} Bookings</h2>
            </div>
            <div>
              <div
                id="w-node-_8b7332c4-3bd2-e30e-a258-6c7e316a7623-16c00861"
                className="no-margin w-form"
              >
                <form
                  id="wf-form-Search"
                  name="wf-form-Search"
                  data-name="Search"
                  method="get"
                  className="search-box"
                  data-wf-page-id="6642a2490a95186d16c00861"
                  data-wf-element-id="8b7332c4-3bd2-e30e-a258-6c7e316a7624"
                  onSubmit={handleFilter}
                >
                  <input
                    className="text-field-2 no-margin w-input"
                    maxLength="256"
                    name="field-4"
                    data-name="Field 4"
                    placeholder="BN/Name/Date/Status"
                    type="text"
                    id="field-4"
                    required=""
                    value={search}
                    onChange={handleSearch}
                  />
                  <button
                    type="button"
                    data-wait="Please wait..."
                    className="button-three w-button"
                    disabled={isGetting}
                  >
                    Filter
                  </button>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
            {isGetting && (
              <img
                src={require("../../common/assets/images/loading-buffering.gif")}
                width="100"
                height="100"
                loading="lazy"
                alt=""
                className="margin-auto mt-20 mb-20"
              />
            )}
            {!!gettingError && (
              <div className="hide-block mb-10">
                <div className="invalid-box">
                  <p className="paragraph-2 _16">
                    <strong>{gettingError}</strong>
                  </p>
                </div>
              </div>
            )}
            {bookings.map((booking) => (
              <AdminBooking
                key={booking.id}
                booking={booking}
                handleCancel={() => handleCancelBooking(booking.id)}
              />
            ))}
            {!isGetting && bookings.length === 0 && <NoItems description="No bookings found." />}
          </div>
        </div>
      </div>
      <div
        className="lightbox"
        style={{
          display: showRemoveDialog ? "flex" : "none",
        }}
      >
        <div className="content-box-large lightbox-content">
          <div>
            <p className="paragraph-2 _16">
              Are you sure you would like to delete this service?
            </p>
            <div className="button-div full-width">
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b4"
                className="button-main full-width w-button"
                onClick={() => setShowRemoveDialog(false)}
              >
                Back
              </button>
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b6"
                className="button-cancel delete full-width w-button"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithNavbar>
  );
};

export default StoreBookings;

import moment from "moment-timezone";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const Slide3 = ({
  slideWidth,
  slideIndex,
  dropOffScheduleDate,
  setDropOffScheduleDate,
  dropOffScheduleTime,
  setDropOffScheduleTime,
  scheduledDates, 
  localTimeZone, 
  isGettingScheduledDates,
  dropOffTimeError = null,
  setDropOffTimeError,
  handlePrevious,
  handleNext,
}) => {
  return (
    <div
      className="slide-1 w-slide"
      style={{
        transform: `translateX(${0 - slideWidth * slideIndex}px)`,
        opacity: 0,
        overflow: "visible",
      }}
    >
      {isGettingScheduledDates && (
        <div className="text-center">
          <img
            src={require("../../../common/assets/images/loading-buffering.gif")}
            width="100"
            height="100"
            loading="lazy"
            alt=""
            className="margin-auto mt-20 mb-20"
          />
        </div>
      )}
      {!isGettingScheduledDates && (
        <div className="form-fields">
          <h2 className="heading-2">Choose a drop off schedule.</h2>
          <div className="spacer"></div>
          <p className="paragraph-2 _16">
            Choose an available date and time to drop off your bicycle for
            service.
          </p>
          <div className="spacer"></div>
          <div className="form-field__wrap">
            <label htmlFor="Make-3" className="field-label-3">
              Date
            </label>
            <DatePicker
              format="MM/dd/yyyy"
              className="text-field-2 w-select"
              onChange={setDropOffScheduleDate}
              value={dropOffScheduleDate}
              calendarProps={{
                tileDisabled: ({ activeStartDate, date, view }) => {
                  if (view === "month") {
                    return !scheduledDates.find(
                      (x) =>
                        moment(x, "YYYY-MM-DD h:mm A").format("YYYY-MM-DD") ===
                        moment(date).format("YYYY-MM-DD")
                    ) || (new Date(moment.tz(date, localTimeZone).format("YYYY-MM-DD"))) < (new Date(moment().format("YYYY-MM-DD")));
                  }

                  if (view === "year") {
                    return !scheduledDates.find(
                      (x) =>
                        moment(x, "YYYY-MM-DD h:mm A").format("YYYY-MM") ===
                        moment(date).format("YYYY-MM")
                    ) || (new Date(moment.tz(date, localTimeZone).format("YYYY-MM"))) < (new Date(moment().format("YYYY-MM")));
                  }

                  if (view === "decade") {
                    return !scheduledDates.find(
                      (x) =>
                        moment(x, "YYYY-MM-DD h:mm A").format("YYYY") ===
                        moment(date).format("YYYY")
                    ) || (new Date(moment.tz(date, localTimeZone).format("YYYY"))) < (new Date(moment().format("YYYY")));
                  }
                },
              }}
            />
          </div>
          <div className="form-field__wrap margin">
            <label htmlFor="Make-3" className="field-label-3">
              Time
            </label>
            <select
              id="Make-3"
              name="Make-3"
              data-name="Make 3"
              required=""
              className="text-field-2 w-select"
              value={dropOffScheduleTime}
              onChange={(e) => {
                setDropOffScheduleTime(e.target.value);
                setDropOffTimeError(null);
              }}
            >
              <option value=""></option>
              {scheduledDates
                .filter((date) =>
                  date.includes(
                    moment(dropOffScheduleDate, "YYYY-MM-DD h:mm A").format("YYYY-MM-DD")
                  ) && (new Date(moment.tz(date, "YYYY-MM-DD h:mm A", localTimeZone))) > (new Date())
                )
                .map((date) => (
                  <option key={date} value={moment(date, "YYYY-MM-DD h:mm A").format("h:mm A")}>
                    {moment(date, "YYYY-MM-DD h:mm A").format("h:mm A")}
                  </option>
                ))}
            </select>
          </div>
          {!!dropOffTimeError && (
            <div className="hide-block mb-10">
              <div className="invalid-box">
                <p className="paragraph-2 _16">
                  <strong>{dropOffTimeError}</strong>
                </p>
              </div>
            </div>
          )}
          <div className="slider-buttons">
            <button
              type="button"
              className="slider-left w-inline-block"
              onClick={handlePrevious}
            >
              <img
                alt=""
                src="https://uploads-ssl.webflow.com/66143990c6c4610f2b2b217a/66143990c6c4610f2b2b224e_arrow.svg"
                className="arrow"
              />
            </button>
            <button
              type="button"
              className="slider-right w-button"
              onClick={() => {
                handleNext();
              }}
            >
              Next Step
            </button>
          </div>
          <div className="slide-counter">
            <div className="dot active-dot"></div>
            <div className="dot active-dot"></div>
            <div className="dot active-dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slide3;

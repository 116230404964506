import { GET_ORDERS_FROM_LOCALLY_URL } from "../common/constants";

export const getAllLocallyOrdersOfStore = async (storeNumber) => {
  return await fetch(GET_ORDERS_FROM_LOCALLY_URL + "?filter=number%7C" + storeNumber + "&sort=createdAt,asc", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_LOCALLY_API_KEY,
    },
  });
};
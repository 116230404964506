import { useState } from "react";
import {
  createAService,
  deleteService,
  getAllServices,
  updateService,
} from "../../services/service";
import { useAuthContext } from "../useAuthContext";

export const useAdminServices = () => {
  const { accessToken } = useAuthContext();
  const [isGetting, setIsGetting] = useState(false);
  const [gettingError, setGettingError] = useState(null);
  const [editError, setEditError] = useState(null);
  const [createError, setCreateError] = useState(null);
  const [removeError, setRemoveError] = useState(null);

  const getServices = async (qString = "") => {
    setIsGetting(true);
    const response = await getAllServices(accessToken, qString);
    const json = await response.json();

    if (response.ok) {
      setIsGetting(false);
      setGettingError(null);
      return json;
    }

    if (!response.ok) {
      setIsGetting(false);
      setGettingError(json.message);
      return [];
    }
  };

  const createService = async (data) => {
    const response = await createAService(data, accessToken);
    const json = await response.json();

    if (response.ok) {
      setCreateError(null);
      return json;
    }

    if (!response.ok) {
      setCreateError(json.message);
      return;
    }
  };

  const editService = async (serviceId, serviceData) => {
    let data = {};

    if (!serviceData.name && !serviceData.description && !serviceData.price) {
      setEditError("All fields are empty.");
      return;
    }

    if (!!serviceData.name) {
      data["name"] = serviceData.name;
    }

    if (!!serviceData.description) {
      data["description"] = serviceData.description;
    }

    if (!!serviceData.price) {
      data["price"] = serviceData.price;
    }

    const response = await updateService(serviceId, data, accessToken);
    const json = await response.json();

    if (response.ok) {
      setEditError(null);
      return json;
    }

    if (!response.ok) {
      setEditError(json.message);
      return;
    }
  };

  const removeService = async (serviceId) => {
    const response = await deleteService(serviceId, accessToken);
    const json = await response.json();

    if (response.ok) {
      setRemoveError(null);
      return json;
    }

    if (!response.ok) {
      setRemoveError(json.message);
      return;
    }
  };

  return {
    getServices,
    isGetting,
    gettingError,
    createService,
    createError, 
    setCreateError, 
    editService,
    editError,
    removeService,
    removeError,
  };
};

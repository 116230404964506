// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-w-icon {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}
.title-w-icon .edit-icon {
  opacity: 0.75;
  background-color: transparent;
}
.title-w-icon .edit-icon .icon-title {
  width: 24px;
}

.w-inloine-block {
  max-width: 100%;
  display: inline-block;
}

.content-box-large .show {
  transition: all 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;EACA,2BAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,6BAAA;AACJ;AACI;EACE,WAAA;AACN;;AAIA;EACE,eAAA;EACA,qBAAA;AADF;;AAKC;EACC,oBAAA;AAFF","sourcesContent":[".title-w-icon {\n  grid-column-gap: 16px;\n  grid-row-gap: 16px;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 20px;\n  display: flex;\n\n  .edit-icon {\n    opacity: 0.75;\n    background-color: transparent;\n\n    .icon-title {\n      width: 24px;\n    }\n  }\n}\n\n.w-inloine-block {\n  max-width: 100%;\n  display: inline-block;\n}\n\n.content-box-large {\n\t.show {\n\t\ttransition: all .2s;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

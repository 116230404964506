import "./StoreCheckItem.scss";

const StoreCheckItem = ({ store, selectedStore, handleStoreClick }) => {
  return (
    <div
      data-w-id="1051521d-53c2-3a44-4af8-dc187d350896"
      className={`w-checkbox checkbox-field-2 checkbox-base ${
        store.id === selectedStore?.id ? "store-checked" : ""
      }`}
      onClick={handleStoreClick}
    >
      <input
        id="New Business"
        name="New-Business"
        data-name="New Business"
        type="checkbox"
        data-w-id="1051521d-53c2-3a44-4af8-dc187d350897"
        className="w-checkbox-input checkbox"
      />
      <div className="div-block-7">
        <img
          src={require("../../common/assets/images/icon-tracking.svg").default}
          loading="lazy"
          alt=""
          className="icon-pin"
        />
        <div className="checkbox-text pin">{store.distance.text}</div>
      </div>
      <div className="checkbox-text-wrapper pointer">
        <h4 className="checkbox-h4">{store.displayName}</h4>
        <div className="checkbox-text">{store.street}</div>
        <div className="checkbox-text">
          {store.city}, {store.state} {store.zip}
        </div>
      </div>
      <span htmlFor="New-Business" className="checkbox-title w-form-label">
        Yes!
      </span>
    </div>
  );
};

export default StoreCheckItem;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdatePassword } from "../../hooks/auth/useUpdatePassword";

const UpdatePassword = () => {
  const { accessToken } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);

  const { updatePassword, isLoading, error, success } = useUpdatePassword();

  const lengthExp = /^.{8,}$/;
  const capitalExp = /[A-Z]/;
  const numberExp = /[0-9]/;
  const nospacesExp = /^\S*$/;

  const isLengthCondition = lengthExp.test(password);
  const isCapitalCondition = capitalExp.test(password);
  const isNumberCondition = numberExp.test(password);
  const isNospacesCondition = nospacesExp.test(password);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordFocus = () => {
    setShowPasswordCheck(true);
  };

  const handlePasswordBlur = () => {
    setShowPasswordCheck(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await updatePassword(password, confirmPassword, accessToken);

    if (result) {
      setPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <section className="section-2">
      <div className="wrapper-2">
        <div className="left">
          <div className="navigation w-clearfix">
            <a href="/" className="logo-link w-inline-block">
              <img
                src={
                  require("../../common/assets/images/horizontal-on-white.svg")
                    .default
                }
                loading="lazy"
                width="182"
                alt=""
                className="logo-2"
              />
            </a>
          </div>
          <div className="form-wrapper">
            <div className="form-block-3 w-form">
              <form className="form-2" onSubmit={handleSubmit}>
                <h1 className="heading-style-1">Reset Password</h1>
                <p className="paragraph-2 _16">Enter a new password.</p>
                <div className="spacer"></div>
                <div className="form-field__wrap margin">
                  <label htmlFor="Name" className="field-label-3">
                    Password*
                  </label>
                  <div className="pw-wrap">
                    <input
                      className="text-field-2 w-input"
                      maxLength="256"
                      name="Name-2"
                      data-name="Name 2"
                      placeholder="Enter Password"
                      data-w-id="f80f1e5e-f45a-bc26-4102-b24c1f4081bf"
                      type={showPassword ? "text" : "password"}
                      id="Name-2"
                      required=""
                      value={password}
                      onChange={handlePasswordChange}
                      onFocus={handlePasswordFocus}
                      onBlur={handlePasswordBlur}
                    />
                    <div
                      data-w-id="291f4375-cf0d-94d8-f385-c09062107b2b"
                      className="eye-wrap"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? (
                        <img
                          id="eye-open"
                          loading="lazy"
                          alt=""
                          src={
                            require("../../common/assets/images/eye-open.svg")
                              .default
                          }
                          className="eye-icon open"
                        />
                      ) : (
                        <img
                          id="eye-close"
                          loading="lazy"
                          alt=""
                          src={
                            require("../../common/assets/images/eye-closed.svg")
                              .default
                          }
                          className="eye-icon closed"
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: showPasswordCheck ? "block" : "none",
                      opacity: showPasswordCheck ? 1 : 0,
                    }}
                    className="div-password"
                  >
                    <div className="spacer"></div>
                    <p className="paragraph-2">Your password must include:</p>
                    <div className={`div-block-8`}>
                      <div
                        className={`pw-require ${
                          isLengthCondition ? "" : "red"
                        }`}
                      >
                        <img
                          width="15"
                          loading="lazy"
                          alt=""
                          src={require(`../../common/assets/images/icon_${
                            isLengthCondition ? "check" : "red"
                          }.png`)}
                          className="icon-check"
                        />
                        <p className="paragraph-2">At least 8 characters</p>
                      </div>
                      <div
                        className={`pw-require ${
                          isCapitalCondition ? "" : "red"
                        }`}
                      >
                        <img
                          width="15"
                          loading="lazy"
                          alt=""
                          src={require(`../../common/assets/images/icon_${
                            isCapitalCondition ? "check" : "red"
                          }.png`)}
                          className="icon-check"
                        />
                        <p className="paragraph-2">
                          At least one capital letter
                        </p>
                      </div>
                      <div
                        className={`pw-require ${
                          isNumberCondition ? "" : "red"
                        }`}
                      >
                        <img
                          width="15"
                          loading="lazy"
                          alt=""
                          src={require(`../../common/assets/images/icon_${
                            isNumberCondition ? "check" : "red"
                          }.png`)}
                          className="icon-check"
                        />
                        <p className="paragraph-2">At least one number</p>
                      </div>
                      <div
                        className={`pw-require ${
                          isNospacesCondition ? "" : "red"
                        }`}
                      >
                        <img
                          width="15"
                          loading="lazy"
                          alt=""
                          src={require(`../../common/assets/images/icon_${
                            isNospacesCondition ? "check" : "red"
                          }.png`)}
                          className="icon-check"
                        />
                        <p className="paragraph-2">No spaces</p>
                      </div>
                    </div>
                    <div className="spacer"></div>
                  </div>
                </div>
                <div className="form-field__wrap">
                  <label htmlFor="Name-4" className="field-label-3">
                    Confirm Password*
                  </label>
                  <div className="pw-wrap">
                    <input
                      className="text-field-2 w-input"
                      maxLength="256"
                      name="Re-enter-Password"
                      data-name="Re-enter Password"
                      placeholder="Enter Password"
                      data-w-id="e2462f9d-db9b-3d92-185c-107ecc5feae1"
                      type={showConfirmPassword ? "text" : "password"}
                      id="Re-enter-Password"
                      required=""
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div
                      data-w-id="e2462f9d-db9b-3d92-185c-107ecc5feae2"
                      className="eye-wrap"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {!showConfirmPassword ? (
                        <img
                          id="eye-open"
                          loading="lazy"
                          alt=""
                          src={
                            require("../../common/assets/images/eye-open.svg")
                              .default
                          }
                          className="eye-icon open"
                        />
                      ) : (
                        <img
                          id="eye-close"
                          loading="lazy"
                          alt=""
                          src={
                            require("../../common/assets/images/eye-closed.svg")
                              .default
                          }
                          className="eye-icon closed"
                        />
                      )}
                    </div>
                  </div>
                  {!!success && (
                    <div className="hide-block mt-0">
                      <div className="invalid-box success-message">
                        <p className="paragraph-2 _16">
                          <strong>Successfully reset!</strong>
                        </p>
                      </div>
                    </div>
                  )}
                  {!!error && (
                    <div className="hide-block">
                      <div className="invalid-box">
                        {typeof error === "string" && (
                          <p className="paragraph-2 _16">
                            <strong>{error}</strong>
                          </p>
                        )}
                        {typeof error === "object" && (
                          <ul className="mb-0">
                            {error.map((errorItem, index) => (
                              <li key={index} className="paragraph-2 _16">
                                <strong>{errorItem}</strong>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="button-normal margin-top w-button"
                  disabled={isLoading}
                >
                  Submit
                  {isLoading && (
                    <div className="spinner-border ms-10" role="status"></div>
                  )}
                </button>
                <p className="paragraph-2 _16 text-center">
                  <a href="/login" className="link-text-button">
                    <span>Go To Log In</span>
                  </a>
                </p>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-wrap">
            <div className="stcky">
              <img
                src={require("../../common/assets/images/Teach.jpg")}
                loading="lazy"
                alt=""
                className="radius-10"
              />
              <div className="text-wrap">
                <p className="p-12">
                  By signing up, you agree to National Assemblers&#x27;{" "}
                  <a href="/" className="link-text-button">
                    Terms of Service
                  </a>{" "}
                  &amp;{" "}
                  <a href="/" className="link-text-button">
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdatePassword;

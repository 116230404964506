import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { GET_STATUS_STRING, GET_ROLES_STRING } from "../../common/constants";
import { useMyOneBooking } from "../../hooks/booking/useMyOneBooking";
import { formatPhoneNumber } from "../../common/functions";

import "./AdminBooking.scss";

const AdminBooking = ({ booking: currentBooking, handleCancel }) => {
  const [booking, setBooking] = useState(currentBooking);
  const [showContent, setShowContent] = useState(false);
  const showRef = useRef(null);
  const [comments, setComments] = useState(null);
  const [content, setContent] = useState("");
  const [status, setStatus] = useState(booking.status);
  const {
    createComment,
    isCreatingComment,
    getComments,
    isGetting,
    updateBooking,
    isUpdating,
  } = useMyOneBooking();

  const handleAddComment = (e) => {
    addComment("<em>" + content + "</em>", content);
  };

  const handleClick = async () => {
    setShowContent(!showContent);
    if (!showContent && !comments) {
      const bookingComments = await getComments(booking.id);
      setComments(bookingComments);
    }
  };

  const handleChangeStatus = async (e) => {
    const bookingResult = await updateBooking(booking.id, {
      status,
    });

    if (bookingResult) {
      const commentStringWithHtml = `Changed service status to <span class=${
        bookingResult.status === "COMPLETED"
          ? "green"
          : bookingResult.status === "INPROGRESS"
          ? "orange-text"
          : "cyan"
      }><strong>${GET_STATUS_STRING[bookingResult.status]}</strong></span>.`;
      const commentString = `Changed service status to ${
        GET_STATUS_STRING[bookingResult.status]
      }`;
      addComment(commentStringWithHtml, commentString);
      setBooking({
        ...booking,
        status: bookingResult.status,
      });
    }
  };

  const addComment = async (textWithHtml, text) => {
    await createComment(textWithHtml, text, booking.id);
    setContent("");
    const bookingComments = await getComments(booking.id);
    setComments(bookingComments);
  };

  useEffect(() => {
    if (showRef.current && showContent) {
      showRef.current.style.height = showRef.current.scrollHeight + "px";
    }

    if (!showContent) {
      showRef.current.style.height = 0;
    }
  }, [comments, showContent]);

  return (
    <div className="content-box-large">
      <div
        data-w-id="cf722af6-11af-80ad-9c8d-9e20e0079f65"
        className="box-click"
        onClick={handleClick}
      >
        <div>
          <p className="paragraph-2 _16">
            <strong>Booking Number</strong>
          </p>
          <p className="paragraph-2 _16">{booking.id}</p>
        </div>
        <div id="w-node-c8d57d90-ee0c-155f-9e9e-151c007e6308-740a7782">
          <p className="paragraph-2 _16">
            <strong>Customer Name</strong>
          </p>
          <p className="paragraph-2 _16">
            {booking.contactFirstName} {booking.contactLastName}
          </p>
        </div>
        <div id="w-node-_47eaf577-802c-ea49-df4c-03eef5eacfce-740a7782">
          <p className="paragraph-2 _16">
            <strong>Booking Date</strong>
          </p>
          <p className="paragraph-2 _16">
            {moment(
              moment(booking.dropOffTime)
                .tz(booking.localTimeZone)
                .format("YYYY-MM-DD h:mm A"),
              "YYYY-MM-DD h:mm A"
            ).format("MMMM DD, YYYY")}
          </p>
        </div>
        <div>
          <p className="paragraph-2 _16">
            <strong>Status</strong>
          </p>
          <div className="status-box">
            <p className="paragraph-2 _16 no-margin">
              <strong
                className={
                  booking.status === "COMPLETED"
                    ? "green"
                    : booking.status === "INPROGRESS"
                    ? "orange-text"
                    : "cyan"
                }
              >
                {GET_STATUS_STRING[booking.status]}
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div
        ref={showRef}
        style={{
          height: showContent ? showRef.current?.scrollHeight : 0,
        }}
        className="show"
      >
        <div>
          <div className="div-show">
            <div
              id="w-node-_1cae0a46-6762-aede-e1d0-78ec15a25f1b-740a7782"
              className="divider margin-top"
            ></div>
            <div id="w-node-d5ee5516-78e5-8fba-17a4-9c0fdf7b7c04-740a7782">
              <p className="paragraph-2 _16">
                <strong>Make</strong>
              </p>
              <p className="paragraph-2 _16">{booking.make}</p>
            </div>
            <div id="w-node-_8f3a7925-872f-bce9-4065-1e61d4d14dc1-740a7782">
              <p className="paragraph-2 _16">
                <strong>Model</strong>
              </p>
              <p className="paragraph-2 _16">{booking.model}</p>
            </div>
            <div id="w-node-_72f94990-faf8-89af-7c86-fb06ea5d1266-740a7782">
              <p className="paragraph-2 _16">
                <strong>Service(s)</strong>
              </p>
              {booking.services.map((service) => (
                <p key={service.id} className="paragraph-2 _16">
                  {service.name}
                </p>
              ))}
            </div>
            <div id="w-node-_8e80bd3b-9ced-d0fe-6adf-9c6ca7434a76-740a7782">
              <p className="paragraph-2 _16">
                <strong>Drop Off Time</strong>
              </p>
              <p className="paragraph-2 _16">
                {moment(
                  moment(booking.dropOffTime)
                    .tz(booking.localTimeZone)
                    .format("YYYY-MM-DD h:mm A"),
                  "YYYY-MM-DD h:mm A"
                ).format("hh:mm a")}{" "}
                to{" "}
                {moment(
                  moment(booking.dropOffTime)
                    .tz(booking.localTimeZone)
                    .format("YYYY-MM-DD h:mm A"),
                  "YYYY-MM-DD h:mm A"
                )
                  .add(1, "hours")
                  .format("hh:mm a")}
              </p>
            </div>
            <div
              id="w-node-ed775e13-8c79-85cf-8a7b-8ee67ee7eba5-740a7782"
              className="divider no-margin"
            ></div>
            <div id="w-node-_586053f4-a109-a6f6-0833-3fc6201aef1b-740a7782">
              <p className="paragraph-2 _16">
                <strong>Service Location</strong>
              </p>
              <p className="paragraph-2 _16">{booking.store.displayName}</p>
              <p className="paragraph-2 _16">
                {booking.store.street}
                <br />
                {booking.store.city}, {booking.store.state} {booking.store.zip}
              </p>
              <p className="paragraph-2 _16">
                {formatPhoneNumber(booking.store.contact)}
              </p>
            </div>
            <div id="w-node-_0c5938b4-f184-5ff9-a0f5-b25d5c5ae065-740a7782">
              <p className="paragraph-2 _16">
                <strong>Customer Contact</strong>
              </p>
              <p className="paragraph-2 _16">
                <a href={`tel:${booking.contactPhone}`}>
                  {formatPhoneNumber(booking.contactPhone)}
                </a>
              </p>
            </div>
            <div
              id="w-node-_655fa62a-45e9-3ade-12b0-772f9e12d5e7-740a7782"
              className="divider no-margin mobile"
            ></div>
            <div
              id="w-node-_88f2f567-eba3-4bd9-728b-2a4117dac951-740a7782"
              className="service-notes"
            >
              <p className="paragraph-2 _16">
                <strong>Service Updates</strong>
              </p>
              {isGetting && (
                <img
                  src={require("../../common/assets/images/loading-buffering.gif")}
                  width="100"
                  height="100"
                  loading="lazy"
                  alt=""
                  className="margin-auto mt-20 mb-20"
                />
              )}
              {!!comments && (
                <div className="flex-16">
                  {comments.map((comment) => (
                    <div key={comment.id} className="service-grid">
                      <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068cac-95e31689">
                        <div className="checkbox-text-wrapper">
                          <div className="service-name">
                            {comment.user.firstName}
                          </div>
                          <div
                            className={`service-title ${
                              comment.user.role === "CUSTOMER"
                                ? "customer"
                                : comment.user.role === "SUPERADMIN"
                                ? "orange-text"
                                : comment.user.role === "RETAIL"
                                ? "walmart"
                                : "cyan"
                            }`}
                          >
                            {GET_ROLES_STRING[comment.user.role]}
                          </div>
                          <div className="checkbox-text">
                            {moment(comment.createdAt).format("MM/DD/YYYY")}
                          </div>
                          <div className="checkbox-text">
                            {moment(comment.createdAt).format("hh:mm A")}
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068cb6-95e31689">
                        <p
                          className="paragraph-2"
                          dangerouslySetInnerHTML={{ __html: comment.content }}
                        ></p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              id="w-node-_301ae549-b845-e0f5-6fcf-5a1c43b503b0-740a7782"
              className="w-form"
            >
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                data-wf-page-id="6645134aefdb1722740a7782"
                data-wf-element-id="301ae549-b845-e0f5-6fcf-5a1c43b503b1"
              >
                <p className="paragraph-2 _16">
                  <strong>Update Status</strong>
                </p>
                <select
                  id="field-2"
                  name="field-2"
                  data-name="Field 2"
                  className="text-field-2 w-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {Object.keys(GET_STATUS_STRING).map((key) => (
                    <option key={key} value={key}>
                      {GET_STATUS_STRING[key]}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  data-wait="Please wait..."
                  className="button-secondary w-button"
                  onClick={handleChangeStatus}
                  disabled={isUpdating}
                >
                  Confirm
                </button>
                <div className="spacer"></div>
                <p className="paragraph-2 _16">
                  <strong>Add Comment</strong>
                </p>
                <textarea
                  id="field"
                  name="field"
                  maxLength="5000"
                  data-name="Field"
                  placeholder="Enter service notes..."
                  className="text-field-2 w-input"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                ></textarea>
                <button
                  type="button"
                  data-wait="Please wait..."
                  className="button-secondary w-button"
                  onClick={handleAddComment}
                >
                  Submit
                </button>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-div right-align">
          <button
            type="button"
            data-w-id="d751554d-9386-b248-f7e4-2c8fb0838df9"
            className="button-cancel w-button"
            onClick={handleCancel}
          >
            Cancel Booking
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBooking;

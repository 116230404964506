import { BOOKINGS_URL } from "../common/constants";

export const createABooking = async (data, accessToken) => {
  return await fetch(BOOKINGS_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const createABookingWithoutToken = async (data) => {
  return await fetch(BOOKINGS_URL + "/withoutToken", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const getMyBookings = async (accessToken, q = "", qDate = "") => {
  return await fetch(BOOKINGS_URL + "?q=" + q + "&qDate=" + qDate, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const removeABooking = async (bookingId, accessToken) => {
  return await fetch(BOOKINGS_URL + '/' + bookingId, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getABooking = async (bookingId, accessToken) => {
  return await fetch(BOOKINGS_URL + "/" + bookingId, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const editBooking = async (bookingId, data, accessToken) => {
  return await fetch(BOOKINGS_URL + "/" + bookingId, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const getUserBookings = async (userId, accessToken) => {
  return await fetch(BOOKINGS_URL + "/user/" + userId, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getStoreBookings = async (storeId, accessToken, qString = "", qDate = "") => {
  return await fetch(BOOKINGS_URL + "/store/" + storeId + "?q=" + qString + "&qDate=" + qDate, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
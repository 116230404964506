import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { formatPhoneNumber } from "../../common/functions";
import { GET_STATUS_STRING, GET_ROLES_STRING } from "../../common/constants";
import { useMyOneBooking } from "../../hooks/booking/useMyOneBooking";
import { useAuthContext } from "../../hooks/useAuthContext";

import "./Booking.scss";

const Booking = ({ booking: currentBooking, handleCancel }) => {
  const { user } = useAuthContext();
  const [booking, setBooking] = useState(currentBooking);
  const [showContent, setShowContent] = useState(false);
  const [comments, setComments] = useState(null);
  const [content, setContent] = useState("");
  const showRef = useRef(null);
  const [status, setStatus] = useState(booking.status);
  const {
    createComment,
    isCreatingComment,
    getComments,
    isGetting,
    updateBooking,
    isUpdating,
  } = useMyOneBooking();

  const handleChangeStatus = async (e) => {
    const bookingResult = await updateBooking(booking.id, {
      status,
    });

    if (bookingResult) {
      const commentStringWithHtml = `Changed service status to <span class=${
        bookingResult.status === "COMPLETED"
          ? "green"
          : bookingResult.status === "INPROGRESS"
          ? "orange-text"
          : "cyan"
      }><strong>${GET_STATUS_STRING[bookingResult.status]}</strong></span>.`;
      const commentString = `Changed service status to ${
        GET_STATUS_STRING[bookingResult.status]
      }`;
      addComment(commentStringWithHtml, commentString);
      setBooking({
        ...booking,
        status: bookingResult.status,
      });
    }
  };

  const addComment = async (textWithHtml, text) => {
    await createComment(textWithHtml, text, booking.id);
    setContent("");
    const bookingComments = await getComments(booking.id);
    setComments(bookingComments);
  };

  const handleAddComment = (e) => {
    addComment("<em>" + content + "</em>", content);
  };

  const handleClick = async () => {
    setShowContent(!showContent);
    if (!showContent && !comments) {
      const bookingComments = await getComments(booking.id);
      setComments(bookingComments);
    }
  };

  useEffect(() => {
    if (showRef.current && showContent) {
      showRef.current.style.height = showRef.current.scrollHeight + "px";
    }

    if (!showContent) {
      showRef.current.style.height = 0;
    }
  }, [comments, showContent]);

  return (
    <div className="content-box-large">
      <div
        data-w-id="58c5bc68-1bdc-45e3-2eab-1288b6068c58"
        className="box-click"
        onClick={handleClick}
      >
        <div>
          <p className="paragraph-2 _16">
            <strong>Booking Number</strong>
          </p>
          <p className="paragraph-2 _16">{booking.id}</p>
        </div>
        <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c5f-95e31689">
          <p className="paragraph-2 _16">
            <strong>Bike</strong>
          </p>
          <p className="paragraph-2 _16">
            {booking.make} {booking.model}
          </p>
        </div>
        <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c65-95e31689">
          <p className="paragraph-2 _16">
            <strong>Booking Date</strong>
          </p>
          <p className="paragraph-2 _16">
            {moment(
              moment(booking.dropOffTime)
                .tz(booking.localTimeZone)
                .format("YYYY-MM-DD h:mm A"),
              "YYYY-MM-DD h:mm A"
            ).format("MMMM DD, YYYY")}
          </p>
        </div>
        <div>
          <p className="paragraph-2 _16">
            <strong>Status</strong>
          </p>
          <div className="status-box">
            <p className="paragraph-2 _16 no-margin">
              <strong
                className={
                  booking.status === "COMPLETED"
                    ? "green"
                    : booking.status === "INPROGRESS"
                    ? "orange-text"
                    : "cyan"
                }
              >
                {GET_STATUS_STRING[booking.status]}
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div ref={showRef} className="show">
        <div>
          <div className="div-show">
            <div
              id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c76-95e31689"
              className="divider margin-top"
            ></div>
            <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c77-95e31689">
              <p className="paragraph-2 _16">
                <strong>Service(s)</strong>
              </p>
              {booking.services.map((service) => (
                <p key={service.id} className="paragraph-2 _16">
                  {service.name}
                </p>
              ))}
            </div>
            <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c7f-95e31689">
              <p className="paragraph-2 _16">
                <strong>Drop Off Time</strong>
              </p>
              <p className="paragraph-2 _16">
                {moment(
                  moment(booking.dropOffTime)
                    .tz(booking.localTimeZone)
                    .format("YYYY-MM-DD h:mm A"),
                  "YYYY-MM-DD h:mm A"
                ).format("hh:mm a")}{" "}
                to{" "}
                {moment(
                  moment(booking.dropOffTime)
                    .tz(booking.localTimeZone)
                    .format("YYYY-MM-DD h:mm A"),
                  "YYYY-MM-DD h:mm A"
                )
                  .add(1, "hours")
                  .format("hh:mm a")}
              </p>
            </div>
            <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c85-95e31689">
              <p className="paragraph-2 _16">
                <strong>Service Location</strong>
              </p>
              <p className="paragraph-2 _16">{booking.store.displayName}</p>
              <p className="paragraph-2 _16">
                {booking.store.street}
                <br />
                {booking.store.city}, {booking.store.state} {booking.store.zip}
              </p>
              <p className="paragraph-2 _16">
                {formatPhoneNumber(booking.store.contact)}
              </p>
            </div>
            <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c8f-95e31689">
              <p className="paragraph-2 _16">
                <strong>Customer Contact</strong>
              </p>
              <p className="paragraph-2 _16">
                <a href={`tel:${booking.contactPhone}`}>
                  {formatPhoneNumber(booking.contactPhone)}
                </a>
              </p>
            </div>
            <div
              id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c96-95e31689"
              className="divider no-margin mobile"
            ></div>
            <div
              id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068c97-95e31689"
              className="service-notes"
            >
              <p className="paragraph-2 _16">
                <strong>Service Updates</strong>
              </p>
              {isGetting && (
                <img
                  src={require("../../common/assets/images/loading-buffering.gif")}
                  width="100"
                  height="100"
                  loading="lazy"
                  alt=""
                  className="margin-auto mt-20 mb-20"
                />
              )}
              {!!comments && (
                <div className="flex-16">
                  {comments.map((comment) => (
                    <div className="service-grid">
                      <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068cac-95e31689">
                        <div className="checkbox-text-wrapper">
                          <div className="service-name">
                            {comment.user.firstName}
                          </div>
                          <div
                            className={`service-title ${
                              comment.user.role === "CUSTOMER"
                                ? "customer"
                                : comment.user.role === "SUPERADMIN"
                                ? "orange-text"
                                : comment.user.role === "RETAIL"
                                ? "walmart"
                                : "cyan"
                            }`}
                          >
                            {GET_ROLES_STRING[comment.user.role]}
                          </div>
                          <div className="checkbox-text">
                            {moment(comment.createdAt).format("MM/DD/YYYY")}
                          </div>
                          <div className="checkbox-text">
                            {moment(comment.createdAt).format("hh:mm A")}
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_58c5bc68-1bdc-45e3-2eab-1288b6068cb6-95e31689">
                        <p
                          className="paragraph-2"
                          dangerouslySetInnerHTML={{ __html: comment.content }}
                        ></p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {booking.status === "COMPLETED" ? (
              <div id="w-node-_832d1fa7-f34c-2722-927a-eca9da41c952-95e31689">
                <p className="paragraph-2 _16">
                  <strong>Rate Service</strong>
                </p>
                <div className="rate-service">
                  <a
                    data-w-id="832d1fa7-f34c-2722-927a-eca9da41c957"
                    href="#"
                    className="star w-inline-block"
                  >
                    <img
                      src={require("../../common/assets/images/1star.png")}
                      loading="lazy"
                      width="28"
                      alt=""
                      className="star-icon _1"
                    />
                  </a>
                  <a
                    data-w-id="832d1fa7-f34c-2722-927a-eca9da41c959"
                    href="#"
                    className="star w-inline-block"
                  >
                    <img
                      src={require("../../common/assets/images/1star.png")}
                      loading="lazy"
                      width="28"
                      alt=""
                      className="star-icon _2"
                    />
                  </a>
                  <a
                    data-w-id="832d1fa7-f34c-2722-927a-eca9da41c95b"
                    href="#"
                    className="star _3 w-inline-block"
                  >
                    <img
                      src={require("../../common/assets/images/1star.png")}
                      loading="lazy"
                      width="28"
                      alt=""
                      className="star-icon _3"
                    />
                  </a>
                  <a
                    data-w-id="832d1fa7-f34c-2722-927a-eca9da41c95d"
                    href="#"
                    className="star _4 w-inline-block"
                  >
                    <img
                      src={require("../../common/assets/images/1star.png")}
                      loading="lazy"
                      width="28"
                      alt=""
                      className="star-icon _4"
                    />
                  </a>
                  <a
                    data-w-id="832d1fa7-f34c-2722-927a-eca9da41c95f"
                    href="#"
                    className="star _5 w-inline-block"
                  >
                    <img
                      src={require("../../common/assets/images/1star.png")}
                      loading="lazy"
                      width="28"
                      alt=""
                      className="star-icon _5"
                    />
                  </a>
                </div>
                <a href="/" className="button-main margin-top w-button">
                  Confirm Rating
                </a>
              </div>
            ) : (
              <div
                id="w-node-_88018c30-eb50-f834-869d-12d3fc520f82-95e31689"
                className="w-form"
              >
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  data-wf-page-id="663a9b2083a5f59195e31689"
                  data-wf-element-id="88018c30-eb50-f834-869d-12d3fc520f83"
                >
                  {(user?.role === "SUPERADMIN" || user?.role === "ADMIN" || user?.role === "TECHNICIAN") && <>
                    <p className="paragraph-2 _16">
                      <strong>Update Status</strong>
                    </p>
                    <select
                      id="field-2"
                      name="field-2"
                      data-name="Field 2"
                      className="text-field-2 w-select"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      {Object.keys(GET_STATUS_STRING).map((key) => (
                        <option key={key} value={key}>
                          {GET_STATUS_STRING[key]}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      data-wait="Please wait..."
                      className="button-secondary w-button"
                      onClick={handleChangeStatus}
                      disabled={isUpdating}
                    >
                      Confirm
                    </button>
                    <div className="spacer"></div>
                  </>}
                  <p className="paragraph-2 _16">
                    <strong>Add Comment</strong>
                  </p>
                  <textarea
                    id="field-7"
                    name="field-7"
                    maxLength="5000"
                    data-name="Field 7"
                    placeholder="Enter your comment..."
                    className="text-field-2 w-input"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  ></textarea>
                  <button
                    type="button"
                    data-wait="Please wait..."
                    className="button-secondary w-button"
                    onClick={handleAddComment}
                    disabled={isCreatingComment}
                  >
                    Submit
                  </button>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {(booking.status === "INPROGRESS" ||
          booking.status === "WAITINGFORDROPOFF") && (
          <div className="button-div right-align">
            <button
              type="button"
              data-w-id="08965fc4-121b-f645-f296-d3ccc6c8c2a1"
              className="button-cancel w-button"
              onClick={() => handleCancel(booking.id)}
            >
              Cancel Booking
            </button>
            {booking.userId === user.id && <a
              href={`/repair/${booking.id}`}
              className="button-main no-shadow w-button"
            >
              Edit Booking
            </a>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Booking;

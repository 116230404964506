import React, { useEffect, useState } from "react";
import moment from "moment";
import { useCreateBooking } from "../../hooks/booking/useCreateBooking";

import Slide1 from "./slides/Slide1";
import Slide2 from "./slides/Slide2";
import Slide3 from "./slides/Slide3";
import Slide4 from "./slides/Slide4";
import Slide5 from "./slides/Slide5";
import Slide6 from "./slides/Slide6";
import Slide7 from "./slides/Slide7";
import { useAuthContext } from "../../hooks/useAuthContext";

import "./Repair.scss";

const Repair = () => {
  const { user } = useAuthContext();
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);
  const [dropOffScheduleDate, setDropOffScheduleDate] = useState(new Date());
  const [dropOffScheduleTime, setDropOffScheduleTime] = useState("");
  const [bikeMake, setBikeMake] = useState("Pacific");
  const [bikeModel, setBikeModel] = useState("GTX");
  const [selectedServices, setSelectedServices] = useState([]);
  const [contactFirstName, setContactFirstName] = useState(
    user ? user.firstName : ""
  );
  const [contactLastName, setContactLastName] = useState(
    user ? user.lastName : ""
  );
  const [contactEmail, setContactEmail] = useState(user ? user.email : "");
  const [contactPhone, setContactPhone] = useState(user ? user.phone : "");
  const [stores, setStores] = useState([]);
  const [services, setServices] = useState([]);
  const [otherServiceClicked, setOtherServiceClicked] = useState(false);
  const [otherServiceContent, setOtherServiceContent] = useState("");
  const [scheduledDates, setScheduledDates] = useState([]);
  const [localTimeZone, setLocalTimeZone] = useState("America/Edmonton");
  const [dropOffTimeError, setDropOffTimeError] = useState(null);

  const {
    getStoresWithoutToken,
    isGettingStores,
    getServicesWithoutToken,
    isGettingServices,
    createBooking,
    isLoading,
    error,
    setError,
    getScheduledDates,
    isGettingScheduledDates, 
  } = useCreateBooking();

  useEffect(() => {
    if (document.querySelector(".w-slider-mask .w-slide")) {
      const oneSlideWidth = document.querySelector(
        ".w-slider-mask .w-slide"
      ).clientWidth;
      setSlideWidth(oneSlideWidth);
    }
  }, []);

  const handleOpacity = (index) => {
    const elems = document.querySelectorAll(".w-slider-mask .w-slide");
    for (let i = 0; i < elems.length; i++) {
      elems[i].style.opacity = 0;
    }

    setTimeout(() => {
      elems[index].style.opacity = 1;
    }, 50);
  };

  const handleNext = async () => {
    if (slideIndex === 2) {
      if (dropOffScheduleTime === "") {
        setDropOffTimeError("Please select a drop off schedule time.");
        return;
      }
    }

    setSlideIndex(slideIndex + 1);
    handleOpacity(slideIndex + 1);

    if (slideIndex === 0) {
      const totalAddress = `${address1} ${address2} ${city}, ${state} ${zip}`;
      const storesResponse = await getStoresWithoutToken(totalAddress);
      setStores(storesResponse);
    }

    if (slideIndex === 1) {
      const datesResponse = await getScheduledDates(selectedStore.number);
      setScheduledDates(datesResponse.dates);
      setLocalTimeZone(datesResponse.localTimeZone);
    }

    if (slideIndex === 3) {
      const servicesResult = await getServicesWithoutToken();
      setServices(servicesResult);
    }
  };

  const handlePrevious = () => {
    setSlideIndex(slideIndex - 1);
    handleOpacity(slideIndex - 1);
  };

  const handleStoreClick = (store) => {
    setSelectedStore(store);
  };

  const handleServiceClick = (serviceParam) => {
    const selectedIds = selectedServices.map((service) => service.id);
    let resServices = [];
    if (!selectedIds.includes(serviceParam.id)) {
      resServices = [...selectedServices, serviceParam];
    } else {
      resServices = selectedServices.filter(
        (service) => service.id !== serviceParam.id
      );
    }

    setSelectedServices(resServices);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedStore) {
      setError("You need to select a store");
      return;
    }

    await createBooking({
      storeId: parseInt(selectedStore.id),
      dropOffTime: moment.tz(
        moment(dropOffScheduleDate, "YYYY-MM-DD h:mm A").format("YYYY-MM-DD") +
          " " +
          dropOffScheduleTime,
        "YYYY-MM-DD h:mm A", 
        localTimeZone
      ).toISOString(),
      make: bikeMake,
      model: bikeModel,
      serviceIds: selectedServices.map((service) => parseInt(service.id)),
      contactFirstName: contactFirstName,
      contactLastName: contactLastName,
      contactEmail: contactEmail.toLowerCase(),
      contactPhone: contactPhone.includes("+")
        ? contactPhone
        : "+" + contactPhone,
      localTimeZone, 
    });
  };

  return (
    <section className="section-2">
      <div className="wrapper-2">
        <div className="left">
          <div className="navigation w-clearfix">
            <a href="/" className="logo-link w-inline-block">
              <img
                src={require("../../common/assets/images/review-logo-walmart.png")}
                loading="lazy"
                width="182"
                alt=""
                className="logo-2"
              />
            </a>
          </div>
          <div className="form-wrapper">
            <div id="SliderForm" className="w-form">
              <form
                id="email-form"
                name="email-form-2"
                data-name="Email Form 2"
                method="post"
              >
                <div
                  data-delay="4000"
                  data-animation="outin"
                  className="slider w-slider"
                  data-autoplay="false"
                  data-easing="ease-out"
                  data-hide-arrows="true"
                  data-disable-swipe="true"
                  data-autoplay-limit="0"
                  data-nav-spacing="3"
                  data-duration="600"
                  data-infinite="false"
                >
                  <div className="mask-2 w-slider-mask">
                    <Slide1
                      slideWidth={slideWidth}
                      slideIndex={slideIndex}
                      address1={address1}
                      setAddress1={setAddress1}
                      address2={address2}
                      setAddress2={setAddress2}
                      city={city}
                      setCity={setCity}
                      state={state}
                      setState={setState}
                      zip={zip}
                      setZip={setZip}
                      handleNext={handleNext}
                    />
                    <Slide2
                      slideWidth={slideWidth}
                      slideIndex={slideIndex}
                      stores={stores}
                      selectedStore={selectedStore}
                      isGettingStores={isGettingStores}
                      handleStoreClick={handleStoreClick}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    />
                    <Slide3
                      slideWidth={slideWidth}
                      slideIndex={slideIndex}
                      dropOffScheduleDate={dropOffScheduleDate}
                      setDropOffScheduleDate={setDropOffScheduleDate}
                      dropOffScheduleTime={dropOffScheduleTime}
                      setDropOffScheduleTime={setDropOffScheduleTime}
                      scheduledDates={scheduledDates}
                      localTimeZone={localTimeZone}
                      isGettingScheduledDates={isGettingScheduledDates}
                      dropOffTimeError={dropOffTimeError}
                      setDropOffTimeError={setDropOffTimeError}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    />
                    <Slide4
                      slideWidth={slideWidth}
                      slideIndex={slideIndex}
                      bikeMake={bikeMake}
                      setBikeMake={setBikeMake}
                      bikeModel={bikeModel}
                      setBikeModel={setBikeModel}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    />
                    <Slide5
                      slideWidth={slideWidth}
                      slideIndex={slideIndex}
                      services={services}
                      isGettingServices={isGettingServices}
                      selectedServices={selectedServices}
                      handleServiceClick={handleServiceClick}
                      otherServiceClicked={otherServiceClicked}
                      setOtherServiceClicked={setOtherServiceClicked}
                      otherServiceContent={otherServiceContent}
                      setOtherServiceContent={setOtherServiceContent}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    />
                    <Slide6
                      slideWidth={slideWidth}
                      slideIndex={slideIndex}
                      contactFirstName={contactFirstName}
                      setContactFirstName={setContactFirstName}
                      contactLastName={contactLastName}
                      setContactLastName={setContactLastName}
                      contactEmail={contactEmail}
                      setContactEmail={setContactEmail}
                      contactPhone={contactPhone}
                      setContactPhone={setContactPhone}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    />
                    <Slide7
                      slideWidth={slideWidth}
                      slideIndex={slideIndex}
                      contactFirstName={contactFirstName}
                      contactLastName={contactLastName}
                      contactEmail={contactEmail}
                      contactPhone={contactPhone}
                      selectedServices={selectedServices}
                      otherServiceClicked={otherServiceClicked}
                      otherServiceContent={otherServiceContent}
                      bikeMake={bikeMake}
                      bikeModel={bikeModel}
                      selectedStore={selectedStore}
                      dropOffScheduleDate={dropOffScheduleDate}
                      dropOffScheduleTime={dropOffScheduleTime}
                      error={error}
                      isLoading={isLoading}
                      handlePrevious={handlePrevious}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                  <div className="left-arrow w-slider-arrow-left">
                    <div className="w-icon-slider-left"></div>
                  </div>
                  <div className="right-arrow w-slider-arrow-right">
                    <div className="icon-2 w-icon-slider-right"></div>
                  </div>
                  <div className="slide-nav w-slider-nav w-slider-nav-invert w-round"></div>
                </div>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-wrap">
            <div className="stcky">
              <img
                src={require("../../common/assets/images/Teach.jpg")}
                loading="lazy"
                alt=""
                className="radius-10"
              />
              <div className="text-wrap centered">
                <p className="p-12">
                  By using this service, you agree to National Assemblers&#x27;{" "}
                  <a href="/" className="link-text-button">
                    Terms of Service
                  </a>{" "}
                  &amp;{" "}
                  <a href="/" className="link-text-button">
                    Privacy Policy
                  </a>
                  .
                </p>
                <p className="p-12">
                  This service is bought to you in partnership with National
                  Assemblers
                </p>
                <img
                  src={
                    require("../../common/assets/images/horizontal-on-whiteSM.svg")
                      .default
                  }
                  loading="lazy"
                  width="191"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Repair;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-field-2.store-checked {
  background-color: rgb(241, 255, 249);
  border-color: rgb(54, 179, 126);
}

.checkbox-field-2 .checkbox-text.pin {
  width: 50px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/StoreCheckItem/StoreCheckItem.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,+BAAA;AACF;;AAGE;EACE,WAAA;EACA,kBAAA;AAAJ","sourcesContent":[".checkbox-field-2.store-checked {\n  background-color: rgb(241, 255, 249);\n  border-color: rgb(54, 179, 126);\n}\n\n.checkbox-field-2 {\n  .checkbox-text.pin {\n    width: 50px;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";

const NumberOnlyRegex = /^[0-9]*$/;
const LetterContainRegEx = /[a-zA-z]/;
const ZIP_CODE_LENGTH = 5;

const ZipCodeInput = ({
  classes, 
  placeholder, 
  zip, 
  setZip, 
}) => {
  const [pressedMeta, setPressedMeta] = useState(false);
  const [hasError, setHasError] = useState();
  const [hasSuccess, setHasSuccess] = useState();

  const handleKeyDown = (event) => {
    const { key, target } = event;

    const isBackspace = key === "Backspace";
    const isMeta = key === "Meta";

    if (target.value.length === 5) {
      return;
    }

    if (isMeta) setPressedMeta(true);
    else if (pressedMeta) setPressedMeta(false);
    else if (!NumberOnlyRegex.test(key) && !isBackspace) {
      setHasError(true);
      event.preventDefault();
    } else {
      setHasError(false);
    }
  };

  const handleOnChange = (event) => {
    const {
      target: { value },
    } = event;

    setHasSuccess(value.length === ZIP_CODE_LENGTH);
    setZip(value);
  };

  const handlePaste = (event) => {
    let value = event.clipboardData.getData("text");

    if (LetterContainRegEx.test(value)) {
      event.preventDefault();
    }
  };

  return (
    <input
      autoComplete="off"
      data-testid="zip-code-input"
      className={classes}
      type="text"
      placeholder={placeholder}
      maxLength={ZIP_CODE_LENGTH}
      // pattern="[0-9]*" // Could be used but the behaviour doesn't work just in time while writing
      value={zip}
      onKeyDown={handleKeyDown}
      onChange={handleOnChange}
      onPaste={handlePaste}
    />
  );
};

export default ZipCodeInput;

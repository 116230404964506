import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useResendEmail } from "../../hooks/auth/useResendEmail";

const ConfirmEmail = () => {
  const { email } = useParams();
  const { resendEmail, isLoading } = useResendEmail();
  const [showMessage, setShowMessage] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [startDate, setStartDate] = useState(new Date());

  const handleResend = async () => {
    setShowMessage(false);
    const result = await resendEmail(email);
    if (result) {
      setStartDate(new Date());
      setShowMessage(true);
    }
  };

  useEffect(() => {
    const resendInterval = setInterval(() => {
      const time = Date.now() - Date.parse(startDate);
      const pastSeconds = Math.floor(time / 1000);
      if (pastSeconds < 60) {
        setSeconds(60 - pastSeconds);
      } else {
        setSeconds(0);
      }
    }, 1000);

    return () => clearInterval(resendInterval);
  }, [startDate]);

  return (
    <div className="w-100vw h-100vh d-flex align-items-center justify-content-center">
      <section className="page-wrap padding">
        <div className="content-box center">
          <img
            src={require("../../common/assets/images/email.svg").default}
            loading="lazy"
            alt=""
            width="56"
            className="icon-main"
          />
          <h1 className="heading-smaller">Verify Account</h1>
          <p className="paragraph-2 _16">
            You have been sent an SMS message to confirm your account. Please
            tap the link in the message to confirm your account.
          </p>
          <div className="spacer"></div>
          {seconds}s<div className="spacer"></div>
          <p className="paragraph-2 _16" style={{
            opacity: seconds === 0 ? 1 : 0.25, 
          }}>
            Didn&#x27;t receive the text?
            <button
              style={{
                backgroundColor: "transparent", 
              }}
              type="button"
              data-w-id="37eda90d-dd8a-13a4-e357-d4851b27a11b"
              className="link-text-button"
              disabled={seconds > 0}
              onClick={handleResend}
            >
              <span> Resend SMS</span>
            </button>
          </p>
          {showMessage && (
            <div className="hide-block">
              <div className="resent-block">
                <p className="paragraph-2 _16">
                  <strong>Verification message has been resent</strong>
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ConfirmEmail;

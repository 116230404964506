import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import Home from "./pages/Home/Home";
import { useAuthContext } from "./hooks/useAuthContext";
import SignUp from "./pages/SignUp/SignUp";
import LogIn from "./pages/LogIn/LogIn";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import Repair from "./pages/Repair/Repair";
import Bookings from "./pages/Bookings/Bookings";
import Admin from "./pages/Admin/Admin";
import ViewUser from "./pages/ViewUser/ViewUser";
import Profile from "./pages/Profile/Profile";
import ConfirmService from "./pages/ConfirmService/ConfirmService";
import StoreBookings from "./pages/StoreBookings/StoreBookings";
import EditBooking from "./pages/Repair/EditBooking";
import Verify from "./pages/Verify/Verify";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import UpdatePassword from "./pages/UpdatePassword/UpdatePassword";

import "./common/assets/styles/normalize.css";
import "./common/assets/styles/components.css";
import "./common/assets/styles/carter-qr.css";
import "./App.scss";

function App() {
  const { user } = useAuthContext();
  const isAdmin = user?.role === "SUPERADMIN" || user?.role === "ADMIN";

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/signup"
            element={!user ? <SignUp /> : <Navigate to="/" />}
          />
          <Route path="/confirm-email/:email" element={<ConfirmEmail />} />
          <Route path="/verify/:userId/:verifyCode" element={<Verify />} />
          <Route
            path="/login"
            element={!user ? <LogIn /> : <Navigate to="/" />}
          />
          <Route
            path="/forgot-password"
            element={!user ? <ForgotPassword /> : <Navigate to="/" />}
          />
          <Route
            path="/update-password/:accessToken"
            element={!user ? <UpdatePassword /> : <Navigate to="/" />}
          />
          <Route path="/repair" element={<Repair />} />
          <Route
            path="/"
            element={
              user ? <Navigate to="/profile" /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/repair/:bookingId"
            element={user ? <EditBooking /> : <Navigate to="/login" />}
          />
          <Route
            path="/bookings"
            element={user ? <Bookings /> : <Navigate to="/login" />}
          />
          <Route path="/confirm-service" element={<ConfirmService />} />
          <Route
            path="/profile"
            element={user ? <Profile /> : <Navigate to="/login" />}
          />
           <Route
            path="/admin"
            element={
              user ? (
                isAdmin ? (
                  <Navigate to="/admin/stores" />
                ) : (
                  <Navigate to="/" />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/admin/:tab"
            element={
              user ? (
                isAdmin ? (
                  <Admin />
                ) : (
                  <Navigate to="/" />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/view-user/:userId"
            element={
              user ? (
                isAdmin ? (
                  <ViewUser />
                ) : (
                  <Navigate to="/" />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/store-bookings/:storeId"
            element={
              user ? (
                isAdmin ? (
                  <StoreBookings />
                ) : (
                  <Navigate to="/" />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="*"
            element={
              <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
                <h1>404 Not Found</h1>
              </div>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

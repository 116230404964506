import { useState } from "react";
import { useAuthContext } from "../useAuthContext";
import { getAllUsers, getOneUser } from "../../services";

export const useAdminUsers = () => {
  const { accessToken } = useAuthContext();
  const [isGettingUsers, setIsGettingUsers] = useState(false);
  const [gettingError, setGettingError] = useState(null);
  const [getAUserError, setGetAUserError] = useState(null);

  const getUsers = async (q = "") => {
    setIsGettingUsers(true);
    setGettingError(null);

    const response = await getAllUsers(accessToken, q);
    const json = await response.json();

    if (response.ok) {
      setIsGettingUsers(false);
      return json;
    }

    if (!response.ok) {
      setIsGettingUsers(false);
      setGettingError(json.message);
      return [];
    }
  };

  const getAUser = async (userId) => {
    const response = await getOneUser(userId, accessToken);
    const json = response.json();

    if (response.ok) {
      return json;
    }

    if (!response.ok) {
      setGetAUserError(json.message);
      return ;
    }
  }

  return { getUsers, isGettingUsers, gettingError, getAUser, getAUserError };
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noitems_container {
  color: #8b939f;
  background-color: #dddfe3;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/NoItems/NoItems.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,yBAAA;EACA,mBAAA;AACF","sourcesContent":[".noitems_container {\n  color: #8b939f;\n  background-color: #dddfe3;\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState, useRef } from "react";
import AdminStore from "../../../components/AdminStore/AdminStore";
import { useAdminStores } from "../../../hooks/admin/useAdminStores";
import ZipCodeInput from "../../../components/ZipCodeInput/ZipCodeInput";
import NoItems from "../../../components/NoItems/NoItems";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AdminStores = ({ activeTabIndex }) => {
  const addStoreContentRef = useRef(null);
  const [showAddStore, setShowAddStore] = useState(false);
  const [stores, setStores] = useState([]);
  const [search, setSearch] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [carter, setCarter] = useState("");
  const [number, setNumber] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [contact, setContact] = useState("");
  const [registrationCode, setRegistrationCode] = useState("");

  const {
    getStores,
    isLoading,
    gettingError,
    createStore,
    isCreating,
    createError,
    setCreateError,
  } = useAdminStores();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const storesResult = await getStores();
    setStores(storesResult);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      getData();
    }
  };

  const handleFilter = async (e) => {
    e.preventDefault();

    const storesResult = await getStores(search);
    setStores(storesResult);
  };

  const clearFields = () => {
    setDisplayName("");
    setCarter("");
    setNumber("");
    setStreet("");
    setCity("");
    setState("");
    setZip("");
    setContact("");
    setRegistrationCode("");

    setCreateError(null);
  };

  const handleCancel = () => {
    clearFields();
    setShowAddStore(false);
  };

  const handleCreate = async () => {
    const createResult = await createStore({
      displayName,
      carter,
      number: parseInt(number),
      street,
      city,
      state,
      zip: parseInt(zip),
      contact: "+" + contact,
      registrationCode,
    });

    if (createResult) {
      setStores([...stores, createResult]);
      clearFields();
    }
  };

  const handleRemove = (storeId) => {
    setStores(stores.filter((store) => store.id !== storeId));
  };

  useEffect(() => {
    if (addStoreContentRef.current && showAddStore) {
      addStoreContentRef.current.style.height =
        addStoreContentRef.current.scrollHeight + "px";
    }

    if (!showAddStore) {
      addStoreContentRef.current.style.height = 0;
    }
  }, [createError, showAddStore]);

  return (
    <div
      data-w-tab="Stores"
      className={`w-tab-pane ${activeTabIndex === 2 ? "w--tab-active" : ""}`}
    >
      <div className="list">
        <div>
          <div
            id="w-node-b9348d48-c740-030d-4674-aa3ad50c9a83-740a7782"
            className="no-margin w-form"
          >
            <form
              id="wf-form-Search"
              name="wf-form-Search"
              data-name="Search"
              method="get"
              className="search-box"
              data-wf-page-id="6645134aefdb1722740a7782"
              data-wf-element-id="b9348d48-c740-030d-4674-aa3ad50c9a84"
              onSubmit={handleFilter}
            >
              <input
                className="text-field-2 no-margin w-input"
                maxLength="256"
                name="field-4"
                data-name="Field 4"
                placeholder="Store/Location/Number"
                type="text"
                id="field-4"
                required=""
                value={search}
                onChange={handleSearch}
              />
              <button
                type="submit"
                data-wait="Please wait..."
                className="button-three w-button"
                disabled={isLoading}
              >
                Filter
              </button>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <div className="title-box">
          <div
            data-w-id="b9348d48-c740-030d-4674-aa3ad50c9a8e"
            className="box-click three-col"
          >
            <div>
              <p className="paragraph-2 _16">
                <strong>Display Name</strong>
              </p>
            </div>
            <div id="w-node-b9348d48-c740-030d-4674-aa3ad50c9a93-740a7782">
              <p className="paragraph-2 _16">
                <strong>Store</strong>
              </p>
            </div>
            <div>
              <p className="paragraph-2 _16">
                <strong>Store Number</strong>
              </p>
            </div>
          </div>
        </div>

        {isLoading && (
          <img
            src={require("../../../common/assets/images/loading-buffering.gif")}
            width="100"
            height="100"
            loading="lazy"
            alt=""
            className="margin-auto mt-20 mb-20"
          />
        )}
        {!!gettingError && (
          <div className="hide-block mb-10">
            <div className="invalid-box">
              <p className="paragraph-2 _16">
                <strong>{gettingError}</strong>
              </p>
            </div>
          </div>
        )}

        {stores.map((store) => (
          <AdminStore
            key={store.id}
            store={store}
            handleRemove={() => handleRemove(store.id)}
          />
        ))}

        {!isLoading && stores.length === 0 && (
          <NoItems description="No stores found." />
        )}

        <div
          ref={addStoreContentRef}
          // style={{
          //   height: showAddStore ? addStoreContentRef.current?.scrollHeight : 0,
          // }}
          className="div-other add-new-store-content-wrapper"
        >
          <div className="content-box-large">
            <div>
              <div>
                <div
                  id="w-node-b6c25904-7751-5f74-a334-648c8bae66c0-740a7782"
                  className="w-form"
                >
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="form-3"
                    data-wf-page-id="6645134aefdb1722740a7782"
                    data-wf-element-id="b6c25904-7751-5f74-a334-648c8bae66c1"
                  >
                    <div id="w-node-b6c25904-7751-5f74-a334-648c8bae66c2-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Display Name</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="Display-Name"
                        data-name="Display Name"
                        placeholder="Enter Display Name"
                        type="text"
                        id="Display-Name"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </div>
                    <div id="w-node-_9c0ac757-04a1-e507-ddc2-703329e3ac40-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Store Name (Carter)</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="Store-Name"
                        data-name="Store Name"
                        placeholder="Enter Store Name"
                        type="text"
                        id="Store-Name-2"
                        value={carter}
                        onChange={(e) => setCarter(e.target.value)}
                      />
                    </div>
                    <div id="w-node-a5ab09d0-6fc0-5f46-8c07-e5ace79a9947-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Store Number</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="Store-Number"
                        data-name="Store Number"
                        placeholder="Enter Store Number"
                        type="text"
                        id="Store-Number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </div>
                    <div id="w-node-_92d3e91e-86da-5fe0-bade-bff301895243-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Street Address</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder="Enter Street Address"
                        type="text"
                        id="field-8"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                      <p className="paragraph-2 _16">
                        <strong>City</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder="Enter City"
                        type="text"
                        id="field-8"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <div className="_2-col-grid">
                        <div id="w-node-facbcb1f-788c-cb30-02a6-ba1fb06fb7f8-740a7782">
                          <label htmlFor="Location-3" className="field-label-3">
                            State
                          </label>
                          <input
                            className="text-field-2 w-input"
                            maxLength="256"
                            name="Location-3"
                            data-name="Location 3"
                            placeholder="FL"
                            type="text"
                            id="Location-3"
                            required=""
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </div>
                        <div id="w-node-facbcb1f-788c-cb30-02a6-ba1fb06fb7fc-740a7782">
                          <label htmlFor="Location-3" className="field-label-3">
                            ZIP Code
                          </label>
                          <ZipCodeInput
                            classes={"text-field-2 w-input"}
                            placeholder={"00000"}
                            zip={zip}
                            setZip={setZip}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="paragraph-2 _16">
                        <strong>Store Contact</strong>
                      </p>
                      <PhoneInput
                        containerClass="text-field-2"
                        inputClass="text-field-2 w-input w-100 h-100"
                        dropdownClass="d-none"
                        buttonClass="d-none"
                        disableSearchIcon={false}
                        countryCodeEditable={false}
                        country={"us"}
                        onlyCountries={["us", "ca"]}
                        value={contact}
                        onChange={(value) => setContact(value)}
                      />
                    </div>
                    <div id="w-node-b6c25904-7751-5f74-a334-648c8bae66cc-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Registration Code</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder="Enter Registration Code"
                        type="text"
                        id="field-8"
                        value={registrationCode}
                        onChange={(e) => setRegistrationCode(e.target.value)}
                      />
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!!createError && (
              <div className="hide-block">
                <div className="invalid-box">
                  {typeof createError === "string" && (
                    <p className="paragraph-2 _16">
                      <strong>{createError}</strong>
                    </p>
                  )}
                  {typeof createError === "object" && (
                    <ul className="mb-0">
                      {createError.map((errorItem, index) => (
                        <li key={index} className="paragraph-2 _16">
                          <strong>{errorItem}</strong>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
            <div className="show">
              <div className="button-div right-align">
                <button
                  type="button"
                  data-w-id="b6c25904-7751-5f74-a334-648c8bae66d9"
                  className="button-cancel w-button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button-main w-button"
                  onClick={handleCreate}
                >
                  Create Store
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          data-w-id="1ad0bf66-cd2d-cad8-cab5-58476159aa9f"
          className="button-main book-button w-button"
          onClick={() => setShowAddStore(true)}
        >
          Add New Store
        </button>
      </div>
    </div>
  );
};

export default AdminStores;

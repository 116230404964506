import {
  FORGOT_PASSWORD_URL,
  GET_ME_URL,
  LOGIN_URL,
  RESEND_VERYFY_EMAIL_URL,
  SIGNUP_URL,
  VERIFY_URL,
} from "../common/constants/urls";

export const getMe = async (accessToken) => {
  return await fetch(GET_ME_URL, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postLogin = async (email, password) => {
  return await fetch(LOGIN_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
};

export const postSignup = async (data) => {
  return await fetch(SIGNUP_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const postVerify = async (userId, verifyCode) => {
  return await fetch(VERIFY_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      verifyCode,
    }),
  });
};

export const resendVerifyEmail = async (email) => {
  return await fetch(RESEND_VERYFY_EMAIL_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });
};

export const postForgotPassword = async (email) => {
  return await fetch(FORGOT_PASSWORD_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email, 
    }),
  });
};

import { useState, useRef, useEffect } from "react";
import AdminService from "../../../components/AdminService/AdminService";
import { useAdminServices } from "../../../hooks/admin/useAdminServices";
import NoItems from "../../../components/NoItems/NoItems";

const AdminServices = ({ activeTabIndex }) => {
  const addServiceContentRef = useRef(null);
  const [showAddService, setShowAddService] = useState(false);
  const [services, setServices] = useState([]);
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const {
    getServices,
    isGetting,
    gettingError,
    createService,
    createError,
    setCreateError,
  } = useAdminServices();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const servicesResult = await getServices();
    setServices(servicesResult);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      getData();
    }
  }

  const handleFilter = async (e) => {
    e.preventDefault();

    const servicesResult = await getServices(search);
    setServices(servicesResult);
  };

  const handleCancel = () => {
    setName("");
    setDescription("");
    setPrice("");
    setCreateError(null);

    setShowAddService(false);
  };

  const handleCreate = async () => {
    const serviceResult = await createService({
      name,
      description,
      price,
    });

    if (serviceResult) {
      setServices([...services, serviceResult]);

      setName("");
      setDescription("");
      setPrice("");
    }
  };

  const handleRemove = (serviceId) => {
    setServices(services.filter((service) => service.id !== serviceId));
  };

  useEffect(() => {
    if (addServiceContentRef.current && showAddService) {
      addServiceContentRef.current.style.height =
        addServiceContentRef.current.scrollHeight + "px";
    }

    if (!showAddService) {
      addServiceContentRef.current.style.height = 0;
    }
  }, [createError, showAddService]);

  return (
    <div
      data-w-tab="Services"
      className={`w-tab-pane ${activeTabIndex === 3 ? "w--tab-active" : ""}`}
    >
      <div className="list">
        <div>
          <div
            id="w-node-_0b814d8b-2be1-f460-4595-1d831c095208-740a7782"
            className="no-margin w-form"
          >
            <form
              id="wf-form-Search"
              name="wf-form-Search"
              data-name="Search"
              method="get"
              className="search-box"
              data-wf-page-id="6645134aefdb1722740a7782"
              data-wf-element-id="0b814d8b-2be1-f460-4595-1d831c095209"
              onSubmit={handleFilter}
            >
              <input
                className="text-field-2 no-margin w-input"
                maxLength="256"
                name="field-4"
                data-name="Field 4"
                placeholder="Service"
                type="text"
                id="field-4"
                required=""
                value={search}
                onChange={handleSearch}
              />
              <button
                type="submit"
                data-wait="Please wait..."
                className="button-three w-button"
              >
                Filter
              </button>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <div className="title-box hide-mobile">
          <div
            data-w-id="0b814d8b-2be1-f460-4595-1d831c095213"
            className="box-click three-col"
          >
            <div>
              <p className="paragraph-2 _16">
                <strong>Service Name</strong>
              </p>
            </div>
            <div id="w-node-_0b814d8b-2be1-f460-4595-1d831c095218-740a7782">
              <p className="paragraph-2 _16">
                <strong>Service Description</strong>
              </p>
            </div>
            <div>
              <p className="paragraph-2 _16">
                <strong>Price</strong>
              </p>
            </div>
          </div>
        </div>

        {isGetting && (
          <img
            src={require("../../../common/assets/images/loading-buffering.gif")}
            width="100"
            height="100"
            loading="lazy"
            alt=""
            className="margin-auto mt-20 mb-20"
          />
        )}
        {!!gettingError && (
          <div className="hide-block mb-10">
            <div className="invalid-box">
              <p className="paragraph-2 _16">
                <strong>{gettingError}</strong>
              </p>
            </div>
          </div>
        )}
        {services.map((service) => (
          <AdminService
            key={service.id}
            service={service}
            handleRemove={() => handleRemove(service.id)}
          />
        ))}

        {!isGetting && services.length === 0 && (
          <NoItems description="No services found." />
        )}

        <div
          ref={addServiceContentRef}
          // style={{
          //   height: showAddService
          //     ? addServiceContentRef.current?.scrollHeight
          //     : 0,
          // }}
          className="div-other add-new-service-content-wrapper"
        >
          <div className="content-box-large">
            <div>
              <div>
                <div
                  id="w-node-af2c69f0-f93f-22d9-89b3-1d27cd974bfb-740a7782"
                  className="w-form"
                >
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="form-3"
                    data-wf-page-id="6645134aefdb1722740a7782"
                    data-wf-element-id="af2c69f0-f93f-22d9-89b3-1d27cd974bfc"
                  >
                    <div id="w-node-af2c69f0-f93f-22d9-89b3-1d27cd974bfd-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Service Name</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder="Enter service name..."
                        type="text"
                        id="field-8"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div>
                      <p className="paragraph-2 _16">
                        <strong>Description</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder="Update service description..."
                        type="text"
                        id="field-8"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div id="w-node-af2c69f0-f93f-22d9-89b3-1d27cd974c07-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Price</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder="49.99"
                        type="number"
                        id="field-8"
                        step={0.01}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!!createError && (
              <div className="hide-block">
                <div className="invalid-box">
                  {typeof createError === "string" && (
                    <p className="paragraph-2 _16">
                      <strong>{createError}</strong>
                    </p>
                  )}
                  {typeof createError === "object" && (
                    <ul className="mb-0">
                      {createError.map((errorItem, index) => (
                        <li key={index} className="paragraph-2 _16">
                          <strong>{errorItem}</strong>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
            <div className="show">
              <div className="button-div right-align">
                <button
                  type="button"
                  data-w-id="af2c69f0-f93f-22d9-89b3-1d27cd974c13"
                  className="button-cancel w-button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  data-w-id="af2c69f0-f93f-22d9-89b3-1d27cd974c15"
                  className="button-main w-button"
                  onClick={handleCreate}
                >
                  Create Service
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="content-box-list">
          <div
            data-w-id="0b814d8b-2be1-f460-4595-1d831c095251"
            className="box-click three-col vertical"
          >
            <div>
              <p className="paragraph-2 _16">
                <strong>Other / I&#x27;m Not Sure</strong>
              </p>
            </div>
            <div id="w-node-_0b814d8b-2be1-f460-4595-1d831c095255-740a7782">
              <p className="paragraph-2 _16"></p>
            </div>
            <div>
              <div className="status-box">
                <p className="paragraph-2 _16">Variable</p>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          data-w-id="fd274589-9331-6a84-3d97-0e9a8931d77f"
          className="button-main book-button w-button"
          onClick={() => setShowAddService(true)}
        >
          Add New Service
        </button>
      </div>
    </div>
  );
};

export default AdminServices;

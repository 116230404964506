import { createContext, useEffect, useReducer } from "react";
import { AuthActionType } from "../common/types";
import { getMe } from "../services";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case AuthActionType.LOGIN:
      return {
        user: action.payload.user,
        accessToken: action.payload.accessToken,
      };
    case AuthActionType.LOGOUT:
      return { user: null, accessToken: null };
    case AuthActionType.SETUSER:
      localStorage.setItem(
        "bikeRepairAuth",
        JSON.stringify({...state, user: action.payload.user})
      );
      return {...state, user: action.payload.user};
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const auth = JSON.parse(localStorage.getItem("bikeRepairAuth"));
  const [state, authDispatch] = useReducer(authReducer, auth ? auth : {
    user: null,
    accessToken: null,
  });

  useEffect(() => {
    if (auth) {
      checkValidToken(auth);
    }
  }, []);
  
  const checkValidToken = async (auth) => {
    const getMeResponse = await getMe(auth.accessToken);

    if (!getMeResponse.ok) {
      localStorage.removeItem("bikeRepairAuth");
      authDispatch({type: AuthActionType.LOGOUT});
    } 
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        authDispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React, { useState } from "react";
import { useForgotPassword } from "../../hooks/auth/useForgotPassword";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { forgotPassword, success, error, isLoading } = useForgotPassword();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await forgotPassword(email.toLowerCase());
  };

  return (
    <section className="section-2">
      <div className="wrapper-2">
        <div className="left">
          <div className="navigation w-clearfix">
            <a href="/" className="logo-link w-inline-block">
              <img
                src={
                  require("../../common/assets/images/horizontal-on-white.svg")
                    .default
                }
                loading="lazy"
                width="182"
                alt=""
                className="logo-2"
              />
            </a>
          </div>
          <div className="form-wrapper">
            <div className="form-block-3 w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                data-ms-form="login"
                className="form-2"
                data-wf-page-id="66104ccbdeedd600c49fd3a2"
                data-wf-element-id="291f4375-cf0d-94d8-f385-c09062107b1d"
                onSubmit={handleSubmit}
              >
                <h1 className="heading-style-1">Forgot Password</h1>
                <p className="paragraph-2 _16">
                  Enter the email address associated with your account.
                </p>
                <div className="spacer"></div>
                <div className="form-field__wrap margin">
                  <label htmlFor="email" className="field-label-3">
                    Email
                  </label>
                  <input
                    className="text-field-2 w-input"
                    maxLength="256"
                    name="email"
                    data-name="email"
                    placeholder="hi@example.com"
                    type="text"
                    id="email"
                    required=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {(!!success || !!error) && <div className="form-field__wrap mb-20">
                  {!!success && (
                    <div className="hide-block mt-0">
                      <div className="invalid-box success-message">
                        <p className="paragraph-2 _16">
                          <strong>Successfully sent!</strong>
                        </p>
                      </div>
                    </div>
                  )}
                  {!!error && (
                    <div className="hide-block mt-0">
                      <div className="invalid-box">
                        {typeof error === "string" && (
                          <p className="paragraph-2 _16">
                            <strong>{error}</strong>
                          </p>
                        )}
                        {typeof error === "object" && (
                          <ul className="mb-0">
                            {error.map((errorItem, index) => (
                              <li key={index} className="paragraph-2 _16">
                                <strong>{errorItem}</strong>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                </div>}
                <button
                  type="submit"
                  className="button-normal w-button"
                  disabled={isLoading}
                >
                  Submit
                  {isLoading && (
                    <div className="spinner-border ms-10" role="status"></div>
                  )}
                </button>
                <p className="paragraph-2 _16 text-center">
                  <a href="/login" className="link-text-button">
                    <span>Back to Login</span>
                  </a>
                </p>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-wrap">
            <img
              src={require("../../common/assets/images/Teach.jpg")}
              loading="lazy"
              alt=""
              className="radius-10"
            />
            <div className="text-wrap">
              <p className="p-12">
                By signing up, you agree to National Assemblers&#x27;{" "}
                <a href="/" className="link-text-button">
                  Terms of Service
                </a>{" "}
                &amp;{" "}
                <a href="/" className="link-text-button">
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-store-content-wrapper,
.add-new-service-content-wrapper {
  transition: all 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Admin.scss"],"names":[],"mappings":"AAAA;;EAEC,oBAAA;AACD","sourcesContent":[".add-new-store-content-wrapper, \n.add-new-service-content-wrapper {\n\ttransition: all .2s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useState } from "react";
import { useAuthContext } from "../useAuthContext";
import {
  editUser,
  getUserBookings,
  getServicesFromIds,
  removeUser,
} from "../../services";

export const useViewUser = () => {
  const { accessToken } = useAuthContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const updateUser = async (userId, userData) => {
    setIsLoading(true);
    setError(null);
    setDeleteError(null);
    setSuccess(null);
    let data = {};

    if (
      !userData.firstName &&
      !userData.lastName &&
      !userData.email &&
      !userData.address &&
      !userData.city &&
      !userData.state &&
      !userData.zip &&
      (userData.phone === "+") &&
      !userData.role &&
      !userData.registrationCode
    ) {
      setError("All fields are empty.");
      setSuccess(null);
      setIsLoading(false);
      return;
    }

    if (!!userData.firstName) {
      data["firstName"] = userData.firstName;
    }

    if (!!userData.lastName) {
      data["lastName"] = userData.lastName;
    }

    if (!!userData.email) {
      data["email"] = userData.email;
    }

    if (!!userData.address) {
      data["address"] = userData.address;
    }

    if (!!userData.city) {
      data["city"] = userData.city;
    }

    if (!!userData.state) {
      data["state"] = userData.state;
    }

    if (!!userData.zip) {
      data["zip"] = parseInt(userData.zip);
    }

    if (userData.phone !== "+1") {
      data["phone"] = userData.phone;
    }

    if (!!userData.role) {
      data["role"] = userData.role;
    }

    if (!!userData.registrationCode) {
      data["registrationCode"] = userData.registrationCode;
    }

    const response = await editUser(userId, data, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsLoading(false);
      setSuccess("Successfully Changed.");
      setError(null);
      return json;
    }

    if (!response.ok) {
      if (response.status === 404) {
        setError("Cannot connect to server");
      } else {
        setError(json.message);
      }
      setSuccess(null);
      setIsLoading(false);
      return;
    }
  };

  const getAllUserBookings = async (userId) => {
    const response = await getUserBookings(userId, accessToken);
    const json = await response.json();

    if (response.ok) {
      for (let i = 0; i < json.length; i++) {
        const serviceIds = json[i].services.map((item) =>
          parseInt(item.serviceId)
        );

        const servicesResponse = await getServicesFromIds(
          { ids: serviceIds },
          accessToken
        );
        const servicesJson = await servicesResponse.json();

        if (servicesResponse.ok) {
          json[i].services = servicesJson;
        }
      }
      return json;
    }

    if (!response.ok) {
      return [];
    }
  };

  const deleteUser = async (userId) => {
    setIsDeleting(true);
    setError(null);
    setDeleteError(null);
    setSuccess(null);

    const response = await removeUser(userId, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsDeleting(false);
      return json;
    }

    if (!response.ok) {
      setIsDeleting(false);
      setDeleteError(json.message);
      return;
    }
  };

  return {
    updateUser,
    error,
    success,
    isLoading,
    getAllUserBookings,
    deleteUser,
    isDeleting,
    deleteError,
  };
};

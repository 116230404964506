import { useEffect, useState } from "react";
import AdminUser from "../../../components/AdminUser/AdminUser";
import { useAdminUsers } from "../../../hooks/admin/useAdminUsers";
import NoItems from "../../../components/NoItems/NoItems";

const AdminUsers = ({ activeTabIndex }) => {
  const { getUsers, isGettingUsers, gettingError } = useAdminUsers();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const usersData = await getUsers();
    setUsers(usersData);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      getData();
    }
  }

  const handleFilter = async (e) => {
    e.preventDefault();

    const usersData = await getUsers(search);
    setUsers(usersData);
  }

  return (
    <div
      data-w-tab="Users"
      className={`w-tab-pane ${activeTabIndex === 1 ? "w--tab-active" : ""}`}
    >
      <div className="list">
        <div>
          <div
            id="w-node-d30136bb-0214-33c1-dab7-fb9a87afdfdc-740a7782"
            className="no-margin w-form"
          >
            <form
              id="wf-form-Search"
              name="wf-form-Search"
              data-name="Search"
              method="get"
              className="search-box"
              data-wf-page-id="6645134aefdb1722740a7782"
              data-wf-element-id="d30136bb-0214-33c1-dab7-fb9a87afdfdd"
              onSubmit={handleFilter}
            >
              <input
                className="text-field-2 no-margin w-input"
                maxLength="256"
                name="field-4"
                data-name="Field 4"
                placeholder="Name/Email/Type"
                type="text"
                id="field-4"
                required=""
                value={search}
                onChange={handleSearch}
              />
              <button
                type="submit"
                data-wait="Please wait..."
                className="button-three w-button"
              >Filter</button>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <div className="title-box">
          <div
            data-w-id="d30136bb-0214-33c1-dab7-fb9a87afdfe7"
            className="box-click three-col"
          >
            <div>
              <p className="paragraph-2 _16">
                <strong>Name</strong>
              </p>
            </div>
            <div id="w-node-d30136bb-0214-33c1-dab7-fb9a87afdfee-740a7782">
              <p className="paragraph-2 _16">
                <strong>Email</strong>
              </p>
            </div>
            <div>
              <p className="paragraph-2 _16">
                <strong>Type</strong>
              </p>
            </div>
          </div>
        </div>
        {isGettingUsers && (
          <img
            src={require("../../../common/assets/images/loading-buffering.gif")}
            width="100"
            height="100"
            loading="lazy"
            alt=""
            className="margin-auto mt-20 mb-20"
          />
        )}
        {!!gettingError && (
          <div className="hide-block mb-10">
            <div className="invalid-box">
              <p className="paragraph-2 _16">
                <strong>{gettingError}</strong>
              </p>
            </div>
          </div>
        )}
        {users.map((user) => (
          <AdminUser key={user.id} user={user} />
        ))}
        {!isGettingUsers && users.length === 0 && (
          <NoItems description="No users found." />
        )}
      </div>
    </div>
  );
};

export default AdminUsers;

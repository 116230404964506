import { USERS_URL } from "../common/constants";

export const updateMe = async (data, accessToken) => {
  return await fetch(USERS_URL, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json", 
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const getAllUsers = async (accessToken, qString) => {
  return await fetch(USERS_URL + "?q=" + qString, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", 
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getOneUser = async (userId, accessToken) => {
  return await fetch(USERS_URL + "/" + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", 
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const editUser = async (userId, data, accessToken) => {
  return await fetch(USERS_URL + "/" + userId, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json", 
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const removeUser = async (userId, accessToken) => {
  return await fetch(USERS_URL + "/" + userId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json", 
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
import { useEffect } from "react";

const Slide4 = ({
  slideWidth,
  slideIndex,
  bikeMake,
  setBikeMake,
  bikeModel,
  setBikeModel,
  handlePrevious,
  handleNext,
}) => {

  useEffect(() => {
    if (bikeMake === "Other") {
      setBikeModel("Other")
    }
  }, [bikeMake])

  return (
    <div
      className="slide-1 w-slide"
      style={{
        transform: `translateX(${0 - slideWidth * slideIndex}px)`,
        opacity: 0,
      }}
    >
      <div className="form-fields">
        <h2 className="heading-2">What type of bike do you have?</h2>
        <div className="spacer"></div>
        <p className="paragraph-2 _16">
          Please fill in the details below so we can know exactly what bike
          repair service you need.
        </p>
        <div className="spacer"></div>
        <div className="form-field__wrap">
          <label htmlFor="Make" className="field-label-3">
            Make
          </label>
          <select
            id="Make"
            name="Make"
            data-name="Make"
            required=""
            className="text-field-2 w-select"
            value={bikeMake}
            onChange={(e) => setBikeMake(e.target.value)}
          >
            <option value="Pacific">Pacific</option>
            <option value="Huffy">Huffy</option>
            <option value="Dynacraft">Dynacraft</option>
            <option value="Hyper">Hyper</option>
            <option value="Kent">Kent</option>
            <option value="Other">Other / I Don&#x27;t Know</option>
          </select>
        </div>
        {bikeMake !== "Other" && <div className="form-field__wrap margin">
          <label htmlFor="Model" className="field-label-3">
            Model
          </label>
          <select
            id="Model"
            name="Model"
            data-name="Model"
            required=""
            className="text-field-2 w-select"
            value={bikeModel}
            onChange={(e) => setBikeModel(e.target.value)}
          >
            <option value="GTX">GT</option>
            <option value="Fastback">Fastback</option>
            <option value="Huffy">Huffy</option>
            <option value="Mongoose">Mongoose</option>
            <option value="Other">I Don&#x27;t Know</option>
          </select>
        </div>}
        <div className="slider-buttons">
          <button
            type="button"
            className="slider-left w-inline-block"
            onClick={handlePrevious}
          >
            <img
              alt=""
              src="https://uploads-ssl.webflow.com/66143990c6c4610f2b2b217a/66143990c6c4610f2b2b224e_arrow.svg"
              className="arrow"
            />
          </button>
          <button
            type="button"
            className="slider-right w-button"
            onClick={handleNext}
          >
            Next Step
          </button>
        </div>
        <div className="slide-counter">
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot active-dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Slide4;

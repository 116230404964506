import React, { useState, useRef, useEffect } from "react";
import { useAdminServices } from "../../hooks/admin/useAdminServices";

import "./AdminService.scss";

const AdminService = ({ service: currentService, handleRemove }) => {
  const [service, setService] = useState(currentService);
  const [showContent, setShowContent] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const showRef = useRef(null);
  const { removeService, removeError, editService, editError } =
    useAdminServices();

  const handleConfirmDelete = async () => {
    const deletedService = await removeService(service.id);
		setShowRemoveDialog(false);

    if (deletedService) {
      handleRemove();
    }
  };

  const handleChange = async () => {
    const serviceResult = await editService(service.id, {
      name,
      description,
      price,
    });

    if (serviceResult) {
      setService(serviceResult);
      clearFields();
    }
  };

  const clearFields = () => {
    setName("");
    setDescription("");
    setPrice("");
  };

  useEffect(() => {
    if (showRef.current && showContent) {
      showRef.current.style.height =
      showRef.current.scrollHeight + "px";
    }

    if (!showContent) {
      showRef.current.style.height = 0;
    }
  }, [removeError, editError, showContent]);


  return (
    <>
      <div className="content-box-large">
        <div
          data-w-id="45a1748a-6473-90d2-3a01-8ffe6a0b11bb"
          className="box-click three-col vertical"
          onClick={() => setShowContent(!showContent)}
        >
          <div>
            <p className="paragraph-2 _16">
              <strong>{service.name}</strong>
            </p>
          </div>
          <div id="w-node-_45a1748a-6473-90d2-3a01-8ffe6a0b11c2-740a7782">
            <p className="paragraph-2 _16">{service.description}</p>
          </div>
          <div id="w-node-_45a1748a-6473-90d2-3a01-8ffe6a0b11c8-740a7782">
            <p className="paragraph-2 _16">${service.price}</p>
          </div>
        </div>
        <div
          ref={showRef}
          // style={{
          //   height: showContent ? showRef.current?.scrollHeight : 0,
          // }}
          className="show"
        >
          <div>
            <div className="div-show">
              <div
                id="w-node-_45a1748a-6473-90d2-3a01-8ffe6a0b11d9-740a7782"
                className="divider margin-top"
              ></div>
              <div
                id="w-node-_45a1748a-6473-90d2-3a01-8ffe6a0b124f-740a7782"
                className="w-form"
              >
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form-3"
                  data-wf-page-id="6645134aefdb1722740a7782"
                  data-wf-element-id="45a1748a-6473-90d2-3a01-8ffe6a0b1250"
                >
                  <div id="w-node-_7d11bd76-fdb9-4c38-cd62-b28f46fc0225-740a7782">
                    <p className="paragraph-2 _16">
                      <strong>Service Name</strong>
                    </p>
                    <input
                      className="text-field-2 w-input"
                      maxLength="256"
                      name="field-8"
                      data-name="Field 8"
                      placeholder={service.name}
                      type="text"
                      id="field-8"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <p className="paragraph-2 _16">
                      <strong>Description</strong>
                    </p>
                    <input
                      className="text-field-2 w-input"
                      maxLength="256"
                      name="field-8"
                      data-name="Field 8"
                      placeholder={service.description}
                      type="text"
                      id="field-8"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div id="w-node-_4d807d85-83fc-bf50-e727-59e66500b0c1-740a7782">
                    <p className="paragraph-2 _16">
                      <strong>Price</strong>
                    </p>
                    <input
                      className="text-field-2 w-input"
                      maxLength="256"
                      name="field-8"
                      data-name="Field 8"
                      placeholder={"$" + service.price}
                      type="number"
                      step={0.01}
                      id="field-8"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!removeError && (
            <div className="hide-block">
              <div className="invalid-box">
                {typeof removeError === "string" && (
                  <p className="paragraph-2 _16">
                    <strong>{removeError}</strong>
                  </p>
                )}
                {typeof removeError === "object" && (
                  <ul className="mb-0">
                    {removeError.map((errorItem, index) => (
                      <li key={index} className="paragraph-2 _16">
                        <strong>{errorItem}</strong>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
          {!!editError && (
            <div className="hide-block">
              <div className="invalid-box">
                {typeof editError === "string" && (
                  <p className="paragraph-2 _16">
                    <strong>{editError}</strong>
                  </p>
                )}
                {typeof editError === "object" && (
                  <ul className="mb-0">
                    {editError.map((errorItem, index) => (
                      <li key={index} className="paragraph-2 _16">
                        <strong>{errorItem}</strong>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
          <div className="button-div right-align">
            <button
              type="button"
              data-w-id="9f72ff43-6b3d-485f-1622-34eeab5d9d26"
              className="button-cancel w-button"
              onClick={() => setShowRemoveDialog(true)}
            >
              Delete Service
            </button>
            <button
              type="button"
              data-w-id="45a1748a-6473-90d2-3a01-8ffe6a0b1263"
              className="button-main w-button"
              onClick={handleChange}
            >
              Apply Changes
            </button>
          </div>
        </div>
      </div>
      <div
        className="lightbox"
        style={{
          display: showRemoveDialog ? "flex" : "none",
        }}
      >
        <div className="content-box-large lightbox-content">
          <div>
            <p className="paragraph-2 _16">
              Are you sure you would like to delete this service?
            </p>
            <div className="button-div full-width">
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b4"
                className="button-main full-width w-button"
                onClick={() => setShowRemoveDialog(false)}
              >
                Back
              </button>
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b6"
                className="button-cancel delete full-width w-button"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminService;

import { useAuthContext } from "../../../hooks/useAuthContext";
import Navbar from "../..//Navbar/Navbar";

const LayoutWithNavbar = ({ children }) => {
  const { user } = useAuthContext();
  return (
    <>
      {!!user && <Navbar />}
      {children}
    </>
  );
};

export default LayoutWithNavbar;

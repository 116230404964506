import { useState } from "react";
import { useAuthContext } from "../useAuthContext";
import { getABooking, editBooking } from "../../services";
import { useNavigate } from "react-router-dom";

export const useEditBooking = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuthContext();
  const [isGettingBook, setIsGettingBook] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getBooking = async (bookingId) => {
    setIsGettingBook(true);
    const response = await getABooking(bookingId, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsGettingBook(false);
      return json;
    }

    if (!response.ok) {
      setIsGettingBook(false);
      navigate("/");
    }
  };

  const updateBooking = async (bookingId, data) => {
    setIsLoading(true);
    const response = await editBooking(bookingId, data, accessToken);
    const json = await response.json();

    if (response.ok) {
      setIsLoading(false);
      navigate("/confirm-service");
    }

    if(!response.ok) {
      if (response.status === 404) {
        setError("Cannot connect to server");
      } else {
        setError(json.message);
      }
      setIsLoading(false)
    }
  }

  return { getBooking, isGettingBook, updateBooking, isLoading, error, setError };
};

import React, { useState, useRef } from "react";
import { formatPhoneNumber } from "../../common/functions";
import { useAdminStores } from "../../hooks/admin/useAdminStores";
import ZipCodeInput from "../ZipCodeInput/ZipCodeInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./AdminStore.scss";

const AdminStore = ({ store: currentStore, handleRemove }) => {
  const [store, setStore] = useState(currentStore);
  const [showContent, setShowContent] = useState(false);
  const [showEditContent, setShowEditContent] = useState(false);
  const [showHeight, setShowHeight] = useState(0);
  const showRef = useRef(null);
  const editRef = useRef(null);
  const editButtonsRef = useRef(null);
  const [displayName, setDisplayName] = useState("");
  const [carter, setCarter] = useState("");
  const [number, setNumber] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [contact, setContact] = useState("1");
  const [registrationCode, setRegistrationCode] = useState("");
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const { editStore, editError, setEditError, removeStore, removeError } =
    useAdminStores();

  const handleChange = async () => {
    const storeResult = await editStore(store.id, {
      displayName,
      carter,
      number: parseInt(number),
      street,
      city,
      state,
      zip: parseInt(zip),
      contact: "+" + contact,
      registrationCode,
    });

    if (storeResult) {
      setStore(storeResult);
      clearFields();
    }
  };

  const clearFields = () => {
    setDisplayName("");
    setCarter("");
    setNumber("");
    setStreet("");
    setCity("");
    setState("");
    setZip("");
    setContact("1");
    setRegistrationCode("");

    setEditError(null);
  };

  const handleCancel = () => {
    setShowEditContent(false);
    setShowHeight(showHeight - editRef.current?.clientHeight);

    clearFields();
  };

  const handleConfirmDelete = async () => {
    const deletedStore = await removeStore(store.id);
    setShowRemoveDialog(false);

    if (deletedStore) {
      handleRemove();
    }
  };

  return (
    <>
      <div className="content-box-large">
        <div
          data-w-id="0aba9689-b14c-f53b-d3ac-9b9253801474"
          className="box-click three-col vertical"
          onClick={() => {
            setShowContent(!showContent);
            setShowHeight(!showContent ? showRef.current.scrollHeight : 0);
          }}
        >
          <div>
            <p className="paragraph-2 _16">{store.displayName}</p>
          </div>
          <div id="w-node-_0aba9689-b14c-f53b-d3ac-9b9253801478-740a7782">
            <p className="paragraph-2 _16">{store.carter}</p>
          </div>
          <div>
            <div className="status-box">
              <p className="paragraph-2 _16">{store.number}</p>
            </div>
          </div>
        </div>
        <div
          ref={showRef}
          style={{
            height: showHeight,
          }}
          className="show"
        >
          <div>
            <div className="div-show _3-col">
              <div
                id="w-node-_7036c6cc-d954-d6a7-c925-22bac032893a-740a7782"
                className="divider margin-top"
              ></div>
              <div id="w-node-_7036c6cc-d954-d6a7-c925-22bac0328956-740a7782">
                <p className="paragraph-2 _16">
                  <strong>Address</strong>
                </p>
                <p className="paragraph-2 _16">{store.displayName}</p>
                <p className="paragraph-2 _16">
                  {store.street}
                  <br />
                  {store.city}, {store.state} {store.zip}
                </p>
              </div>
              <div id="w-node-_7036c6cc-d954-d6a7-c925-22bac0328960-740a7782">
                <p className="paragraph-2 _16">
                  <strong>Store Contact</strong>
                </p>
                <p className="paragraph-2 _16">
                  <a href="tel:+13059138439">
                    {formatPhoneNumber(store.contact)}
                  </a>
                </p>
              </div>
              <div id="w-node-fdcbcce2-de0e-1530-18ab-a1dbb45b07fd-740a7782">
                <p className="paragraph-2 _16">
                  <strong>Registration Code</strong>
                </p>
                <p className="paragraph-2 _16">{store.registrationCode}</p>
              </div>
              <div
                ref={editRef}
                id="w-node-_762a6716-732e-93fe-1a86-4c7cb6c9cd0e-740a7782"
                style={{
                  height: showEditContent ? editRef.current?.scrollHeight : 0,
                }}
                className="div-view"
              >
                <div
                  id="w-node-_506f77db-88b2-866c-02f4-63c01f9b4411-740a7782"
                  className="w-form"
                >
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="form-3"
                    data-wf-page-id="6645134aefdb1722740a7782"
                    data-wf-element-id="506f77db-88b2-866c-02f4-63c01f9b4412"
                  >
                    <div id="w-node-_506f77db-88b2-866c-02f4-63c01f9b4413-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Display Name</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="Display-Name-2"
                        data-name="Display Name 2"
                        placeholder={store.displayName}
                        type="text"
                        id="Display-Name-2"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </div>
                    <div id="w-node-_506f77db-88b2-866c-02f4-63c01f9b4418-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Store Name (Carter)</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="Store-Name-3"
                        data-name="Store Name 3"
                        placeholder={store.carter}
                        type="text"
                        id="Store-Name-3"
                        value={carter}
                        onChange={(e) => setCarter(e.target.value)}
                      />
                    </div>
                    <div id="w-node-_506f77db-88b2-866c-02f4-63c01f9b441d-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Store Number</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="Store-Number-2"
                        data-name="Store Number 2"
                        placeholder={store.number}
                        type="text"
                        id="Store-Number-2"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </div>
                    <div id="w-node-_506f77db-88b2-866c-02f4-63c01f9b4422-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Street Address</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder={store.street}
                        type="text"
                        id="field-8"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                      <p className="paragraph-2 _16">
                        <strong>City</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder={store.city}
                        type="text"
                        id="field-8"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <div className="_2-col-grid">
                        <div id="w-node-_506f77db-88b2-866c-02f4-63c01f9b442c-740a7782">
                          <label htmlFor="Location-4" className="field-label-3">
                            State
                          </label>
                          <input
                            className="text-field-2 w-input"
                            maxLength="256"
                            name="Location-3"
                            data-name="Location 3"
                            placeholder={store.state}
                            type="text"
                            id="Location-3"
                            required=""
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </div>
                        <div id="w-node-_506f77db-88b2-866c-02f4-63c01f9b4430-740a7782">
                          <label htmlFor="Location-3" className="field-label-3">
                            ZIP Code
                          </label>
                          <ZipCodeInput
                            classes={"text-field-2 w-input"}
                            placeholder={store.zip}
                            zip={zip}
                            setZip={setZip}
                          />
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_506f77db-88b2-866c-02f4-63c01f9b4434-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Store Contact</strong>
                      </p>
                      <PhoneInput
                        containerClass="text-field-2"
                        inputClass="text-field-2 w-input w-100 h-100"
                        dropdownClass="d-none"
                        buttonClass="d-none"
                        disableSearchIcon={false}
                        countryCodeEditable={false}
                        country={"us"}
                        onlyCountries={["us", "ca"]}
                        value={contact}
                        onChange={(value) => setContact(value)}
                      />
                    </div>
                    <div id="w-node-_506f77db-88b2-866c-02f4-63c01f9b4439-740a7782">
                      <p className="paragraph-2 _16">
                        <strong>Registration Code</strong>
                      </p>
                      <input
                        className="text-field-2 w-input"
                        maxLength="256"
                        name="field-8"
                        data-name="Field 8"
                        placeholder={store.registrationCode}
                        type="text"
                        id="field-8"
                        value={registrationCode}
                        onChange={(e) => setRegistrationCode(e.target.value)}
                      />
                      {!!editError && (
                        <div className="hide-block">
                          <div className="invalid-box">
                            {typeof editError === "string" && (
                              <p className="paragraph-2 _16">
                                <strong>{editError}</strong>
                              </p>
                            )}
                            {typeof editError === "object" && (
                              <ul className="mb-0">
                                {editError.map((errorItem, index) => (
                                  <li key={index} className="paragraph-2 _16">
                                    <strong>{errorItem}</strong>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="button-div right-align">
                        <button
                          type="button"
                          data-w-id="574054f2-f888-b287-fdd4-59c7f051daf2"
                          className="button-cancel w-button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="button-main w-button"
                          onClick={handleChange}
                        >
                          Apply Changes
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {!!removeError && (
            <div className="hide-block">
              <div className="invalid-box">
                {typeof removeError === "string" && (
                  <p className="paragraph-2 _16">
                    <strong>{removeError}</strong>
                  </p>
                )}
                {typeof removeError === "object" && (
                  <ul className="mb-0">
                    {removeError.map((errorItem, index) => (
                      <li key={index} className="paragraph-2 _16">
                        <strong>{errorItem}</strong>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
          {!showEditContent && (
            <div ref={editButtonsRef} className="button-div right-align">
              <button
                type="button"
                data-w-id="9f72ff43-6b3d-485f-1622-34eeab5d9d26"
                className="button-cancel w-button"
                onClick={() => setShowRemoveDialog(true)}
              >
                Delete Store
              </button>
              <button
                type="button"
                data-w-id="2d4a3d53-0b2a-b518-8782-55330c587842"
                className="button-main w-button"
                onClick={() => {
                  setShowEditContent(true);
                  if (!showEditContent) {
                    setShowHeight(showHeight + editRef.current?.scrollHeight);
                  }
                }}
              >
                Edit Store
              </button>
              <a
                href={`/store-bookings/${store.id}`}
                className="button-main w-button"
              >
                View Bookings
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        className="lightbox"
        style={{
          display: showRemoveDialog ? "flex" : "none",
        }}
      >
        <div className="content-box-large lightbox-content">
          <div>
            <p className="paragraph-2 _16">
              Are you sure you would like to delete this store?
            </p>
            <div className="button-div full-width">
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b4"
                className="button-main full-width w-button"
                onClick={() => setShowRemoveDialog(false)}
              >
                Back
              </button>
              <button
                type="button"
                data-w-id="78101b1d-4620-6500-34b3-4916aca2c6b6"
                className="button-cancel delete full-width w-button"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminStore;

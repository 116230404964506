// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-logout {
  background-color: transparent;
}

@media (max-width: 991px) {
  .w-nav div.w-nav-overlay {
    height: calc(100vh - 60px);
    transition: height 400ms ease 0s;
  }
  .w-nav div.w-nav-overlay .w-nav-menu {
    transition: transform 400ms ease 0s;
  }
  .w-nav div.w-nav-overlay .w-nav-menu .btn-logout {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;AACD;;AAEA;EAEE;IACC,0BAAA;IACA,gCAAA;EAAD;EAEC;IACC,mCAAA;EAAF;EAEE;IACC,WAAA;EAAH;AACF","sourcesContent":[".btn-logout {\n\tbackground-color: transparent;\n}\n\n@media (max-width: 991px) {\n\t.w-nav {\n\t\tdiv.w-nav-overlay {\n\t\t\theight: calc(100vh - 60px);\n\t\t\ttransition: height 400ms ease 0s;\n\n\t\t\t.w-nav-menu {\n\t\t\t\ttransition: transform 400ms ease 0s;\n\n\t\t\t\t.btn-logout {\n\t\t\t\t\twidth: 100%;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

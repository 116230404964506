import { useState } from "react";
import { postVerify } from "../../services";

export const useVerify = () => {
  const [verifyError, setVerifyError] = useState(false);

  const verifyEmail = async (userId, verifyCode) => {
    const response = await postVerify(userId, verifyCode);
    const json = await response.json();

    if (response.ok) {
      return json;
    }

    if (!response.ok) {
      setVerifyError(json.message);
      return;
    }
  };

  return { verifyEmail, verifyError };
};
